.project-detail-layout {
 width: 100%;
 display: flex;
}
.project-detail-container {
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 width: 100%;
}
.form-section {
 display: flex;
 flex-direction: column;
 gap: 16px;
}
.title-section {
 display: flex;
 gap: 4px;
 flex-direction: column;
}
.project-brief-title {
 font-size: var(--font-size-1);
 font-weight: 600;
 line-height: 28px;
 color: var(--secondary-button-color) !important;
}
.project-brief-description {
 font-size: var(--font-size-4);
 font-weight: 400;
 line-height: 16px;
 color: rgba(31, 31, 31, 1);
}
.input-field::placeholder {
 color: #7c8698;
}
.form-container {
 display: flex;
 gap: 8px;
 flex-direction: column;
}
.form-content {
 gap: 16px;
 display: flex;
 flex-direction: column;
}
.field-container {
 display: flex;
 gap: 4px;
 flex-direction: column;
}
.project-field-name-container {
 display: flex;
 gap: 4px;
 align-items: center;
}
.project-field-name {
 font-size: var(--font-size-3);
 font-weight: 600;
 line-height: 20px;
 color: var(--secondary-button-color) !important;
}
.project-field-name > span {
 color: rgba(102, 102, 102, 1);
}
.phone-number-field {
 display: flex;
 align-items: center;
 gap: 8px;
}
.info-icon {
 width: 16px;
 height: 16px;
 display: flex;
 cursor: pointer;
}
.info-icon img {
 width: 100%;
 height: 100%;
}
.input-field-container {
 border-radius: 8px;
 border: 1px solid rgba(204, 204, 204, 1);
}
.budget-input-field-container {
 border-radius: 8px;
 border: none;
 cursor: pointer;
}
.project-brief-select-field-container {
 display: flex;
 justify-content: space-between;
 border: none;
 border-radius: 8px;
 width: 100%;
 align-items: center;
 padding: 0px 11px;
}
.budget-select-field-container {
 display: flex;
 justify-content: space-between;
 padding: 6px 16px;
 border: 1px solid var(--secondary-button-border-color);
 border-radius: 8px;
 width: calc(100% - 32px);
 width: 100%;
 align-items: center;
}
.project-brief-select-field {
 font-size: var(--font-size-3);
 border: none;
 font-weight: 400;
 border-radius: 8px;
 line-height: 20px;
 color: #7c8698;
 width: 100%;
}

.input-field {
 font-size: var(--font-size-3);
 border: none;
 font-weight: 400;
 border-radius: 8px;
 width: calc(100% - 32px);
 line-height: 20px;
}
.input-field:focus-visible {
 outline-width: 0;
}

.textarea-field:focus-visible {
 outline-width: 0;
}
.textarea-field {
 height: 92px;
 padding: 11px 11px;
 resize: none;
 font-size: var(--font-size-3);
 border: none;
 font-weight: 500;
 border-radius: 8px;
 width: calc(100% - 32px);
 line-height: 20px;
}
.textarea-field::placeholder {
 color: rgba(151, 151, 151, 1);
}

.textarea-wordcount {
 color: rgba(151, 151, 151, 1);
 padding: 0px 11px 11px;
 display: flex;
 justify-content: flex-end;
 font-size: 12px;
 font-weight: 600;
 color: rgba(151, 151, 151, 1);
}
.back-content {
 display: flex;
 justify-content: center;
}
.skip-content {
 color: #043f2e;
 font-weight: 600;
 font-size: var(--font-size-3);
 cursor: pointer;
 text-decoration: underline;
}
.error-message-container {
 display: flex;
 gap: 4px;
 align-items: center;
}
.error-message-image {
 width: 16px;
 height: 16px;
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
 .project-detail-container {
  padding: 20px 14px;
 }
 .form-section {
  gap: 12px;
 }
 .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 15px;
 }
 .description {
  font-size: var(--font-size-4);
  font-weight: 400;
  line-height: 10px;
 }
 .form-container {
  gap: 4px;
 }
 .form-content {
  gap: 14px;
  padding: 14px;
 }
 .project-field-name {
  font-size: var(--font-size-4);
  line-height: 15px;
 }
 .info-icon {
  width: 12px;
  height: 12px;
 }
 .project-brief-select-field-container {
  padding: 4px 11px;
  height: 40px !important;
 }
 .select-field {
  font-size: var(--font-size-4);
  line-height: 15px;
 }
 .input-field {
  padding: 4px 16px;
  font-size: var(--font-size-4);
 }
 .textarea-field {
  padding: 11px 0px 0px 11px;
  height: 80px;
  font-size: var(--font-size-4);
  line-height: 15px;
 }
}

@media only screen and (max-width: 991px) {
 .title {
  font-size: var(--font-size-3);
  line-height: 20px;
 }
}

.agency-form-layout {
 width: 100%;
 display: flex;
}

.agency-form-container {
 padding: 24px;
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 gap: 16px;
 width: calc(100% - 48px);
}
.agency-form-content {
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 gap: 8px;
}

.project-brief-form-field {
 display: flex;
 gap: 8px;
 flex-direction: column;
 width: 100%;
}

.project-brief-field-name-container {
 display: flex;
 gap: 4px;
 align-items: center;
}
.project-brief-field-name {
 font-size: var(--font-size-3);
 font-weight: 600;
 line-height: 20px;
 color: var(--secondary-button-color) !important;
}

.info-icon {
 width: 16px;
 height: 16px;
 cursor: pointer;
}
.info-icon-img {
 width: 100%;
 height: 100%;
}

.project-brief-select-field-container {
 border-radius: 8px;
 height: 45px;
 border: 1px solid rgba(204, 204, 204, 1);
 position: relative;
 cursor: pointer;
 background: none;
}
.project-brief-select-field {
 padding: 6px 0px;
 border-radius: 8px;
 display: flex;
 justify-content: space-between;
}
.project-brief-select-field input {
 border: none;
}
.project-brief-select-field input:focus-visible {
 outline: none;
}
.project-brief-dropdown-option-name {
 font-size: var(--font-size-3);
 font-weight: 400;
 color: var(--secondary-button-color) !important;
 cursor: pointer;
}
.project-brief-dropdown-option-name:hover,
.project-brief-dropdown-option-name.hovered {
  text-decoration: underline;
}

.project-brief-dropdown-field-name {
 font-size: var(--font-size-3);
 font-weight: 400;
 color: #7c8698;
}

.arrow-icon {
 width: 16px;
 height: 16px;
 cursor: pointer;
}
.arrow-icon-img {
 width: 100%;
 height: 100%;
}
.input-field::placeholder {
 color: #7c8698;
}
.input-field {
 font-size: var(--font-size-3);
 border: none;
 font-weight: 400;
 border-radius: 8px;
 width: 100%;
 line-height: 20px;
}
.input-field:focus-visible {
 outline-width: 0;
}
.project-brief-dropdown-field {
 display: flex;
 flex-direction: column;
 gap: 4px;
 position: relative;
}
.dropdown-list {
 max-height: 145px;
 overflow: auto;
}

.project-brief-dropdown-container {
 width: 100%;
 position: absolute;
 margin-top: 40px;
 background-color: white;
 border-radius: 8px;
 box-shadow: 0px 1.67px 3.33px 0px #091e4214;
 z-index: 1;
 border-radius: 4px;
 border: 0.83px solid var(--secondary-button-border-color);
}

.project-brief-dropdown-content {
 display: flex;
 padding: 4px;
 flex-direction: column;
 gap: 4px;
}
.project-brief-dropdown-field-container {
 gap: 4px;
 display: flex;
 width: 100%;
 border-radius: 8px;
}

.dropdown-field-container:hover,
.dropdown-field-container.selected {
 background-color: #f4f5f7;
}
.project-brief-search-field-container {
 gap: 4px;
 display: flex;
 width: 100%;
 border-radius: 8px;
 border: 1px solid var(--secondary-button-border-color);
}
.search-field {
 padding: 6px 10px;
 display: flex;
 gap: 4px;
 align-items: center;
 width: 100%;
}
.button-container {
 display: flex;
 gap: 8px;
 align-items: center;
 justify-content: flex-start;
}
