:root {
  /*------color-----------*/
  --primary-color: #A9F005;
  --secondary-color: #EEF2E3;
  --primary-button-color: #002502;
  --title-background-color:#DAF4FF66;
  --title-border-color:#0091FF;
  --content-color:#1F1F1F;
  --secondary-button-border-color: #dfe1e6;
  --disable-button-bgcolor:#9797971A;
  --disable-button-color: #1F1F1F6B;
  /*--------font sizes--------*/
  --font-size-1: 20px;
  --font-size-2: 16px;
  --font-size-3: 14px;  
  --font-size-4: 12px;
}
