.types-container {
 display: flex;
 gap: 13px;
 padding: 20px;
 flex-direction: column;
 height: calc(100vh - 140px);
 overflow-y: scroll;
 position: sticky;
 top: 100px;
}
.category-type-container {
 display: flex;
 gap: 8px;
 padding: 12px;
 border-top-right-radius: 8px;
 border-bottom-right-radius: 8px;
 cursor: pointer;
}
.category-image {
 width: 20px;
 height: 20px;
}
.category-image img {
 width: 100%;
 height: 1005;
}
.category-type-name {
 color: var(--content-color);
 font-weight: 600;
 font-size: var(--font-size-3);
}


@media only screen and (max-width: 991px) {
    .types-container {
        display: flex;
 gap: 13px;
 padding: 20px;
 flex-direction: column;
 height: 100%;
 overflow-y: scroll;
 position: inherit;
       }
       .category-type-container {
        display: flex;
        gap: 8px;
        padding: 12px;
        background: #eeeeee6b;
        border-radius: 8px;
        cursor: pointer;
       }
   }