.chip {
  border: 1px solid var(--secondary-button-border-color);
  border-radius: 40px;
  padding: 4px 8px;
  font-size: var(--font-size-4);
  width: fit-content;
  font-weight: 400;
  line-height: 16px;
  font-weight: 400;
  display: flex;
  gap: 4px;
  align-items: center;
}
