.landingpage-container{
    background: #101010;
width: 100%;
min-height: 100vh;
color: white;
display: flex;
justify-content: center;
}
.landingpage-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
.landingpage-header-container{
    position: sticky;
    top: 0px;
    z-index: 11;
    height: fit-content;
    background: #101010;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px dashed #FFFFFF1F;
}
.landing-header-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 26px 0px;
    max-width: 1176px;
}
.landing-header-logo-container{
    display: flex;
    gap: 9px;
    align-items: center;
    cursor: pointer;
}
.landingpage-components-container{
    display: flex;
    flex-direction: column;
    padding: 71px 0px 0px 0px;
}
.landing-navigation-tabs{
    display: flex;
    gap: 60px;
    display: flex;
    align-items: center;
}
.landing-tabs{
    color: #FFFFFF;
    font-size: var(--font-size-3);
    font-weight: 400;
    cursor: pointer;
}
.landing-tabs-forum{
    pointer-events: none;
}
.landing-tabs:hover{
    text-decoration: underline;
    text-decoration-style: wavy;
    text-decoration-skip-ink: false;
    text-decoration-thickness: 10%;
    text-decoration-color:var(--primary-color);
}

button{
    background:none;
    border: none;
}
.hire-button {
    width: 145px;
   }

   .landingpage-toptier-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 48px;
   }
   .toptier-title-container{
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
   }
   .toptier-title-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
   }
   .toptier-title1{
    background: linear-gradient(90deg, #FFFFFF 0%, #999999 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
font-size: 36px;
font-weight: 900;
line-height: 47px;
line-height: 1;
padding: 0; 
margin: 0; 
display: inline-block; 
height: auto; 
   }

.toptier-title1, .toptier-title2 {
    transform: translateY(20%);
    opacity: 0; 
    animation-duration: 0.8s; 
    animation-fill-mode: forwards; 
  }
  
  .toptier-title1 {
    animation-name: slideUp1;
  }
  
  .toptier-title2 {
    animation-name: slideUp2;
  }
  
  @keyframes slideUp1 {
    0% {
      transform: translateY(30%); 
      opacity: 0;
    }
    100% {
      transform: translateY(0%); 
      opacity: 1;
    }
  }
  
  @keyframes slideUp2 {
    0% {
      transform: translateY(30%); 
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  
   .toptier-title2{
    background: linear-gradient(0deg, #000000 18.67%, #347928 47.29%, #A9F005 75.9%, #F3FFE6 133.13%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
font-size: 64px;
font-weight: 900;
line-height: 83px;
line-height: 1; 
padding: 0;
margin: 0; 
display: inline-block;
height: auto; 
   }
   .toptier-title3{
    background: linear-gradient(90deg, #FFFFFF 0%, #999999 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 64px;
    font-weight: 800;
    line-height: 1.2; 
    padding: 0;
    margin: 0; 
    display: inline-block; 
    height: auto; 
    text-align: center;
   }
   .toptier-content{
    color: #E6E6E6;
    font-size: var(--font-size-3);
    font-weight: 300;
    width: 600px;
    text-align: center;
   }
   .toptier-button-container{
    display: flex;
    gap: 14px;
   }
   .toptier-buttons{
    width: 200px;
   }
.toptier-info-container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.toptier-info-box{
    border-left: 2px solid #347928;
    width: 195px;
    padding: 9px 12px;
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.toptier-info-content1{
    color: #FFFFFF;
    font-size: var(--font-size-2);
    font-weight: 800;
    text-align: center;
}
.toptier-info-content2{
    color: #FFFFFFCC;
    font-weight: 300;
    font-size: var(--font-size-3);
    text-align: center;
}

.landingpage-footer-container{
    display: flex;
    flex-direction: column;
    padding: 153px 0px 40px 0px;
    gap:60px;
    align-items: center;
    width: 100%;
}
.landingpage-footer-category{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    max-width: 1176px;
}
.landingpage-footer-category-tags{
    width: 210px;
    height: 42px;
    align-items: center;
    display: flex;
}
.landing-footer-end-content{
    border-top: 2px solid #333333;
width: 100%;
display: flex;
justify-content: center;
}
.footer-category-name{
    color: #FFFFFF;
    font-size: var(--font-size-3);
    font-weight: 400;
    cursor: pointer;
}
.footer-category-name:hover{
    text-decoration: underline;
    text-decoration-style: wavy;
    text-decoration-skip-ink: false;
    text-decoration-thickness: 10%;
    text-decoration-color:var(--primary-color);
}
.footer-end-container{
    display: flex;
    justify-content: space-between;
    padding: 30px 5px;
    max-width: 1176px;
    width: 100%;
}
.footer-logo-container{
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.footer-cc{
    color:#979797;
    font-weight: 400;
    font-size: var(--font-size-3);
}
.card-carousel{
display: flex;
max-width: 100%;
}
.landing-card-carousel{
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 100vw;
    justify-content: flex-start;
   padding: 120px 0px 100px 0px;
}
.card-carousel-container{
    overflow-x: auto;
    white-space: nowrap;
    transition: scroll 0.3s ease-in-out;
}

.card-carousel-container::-webkit-scrollbar {
    display: none;
}

.landing-scroll-card-container{
    backdrop-filter: blur(40px);
    min-width: 240px;
    min-height: fit-content;
    border-radius: 12px;
    background: #FFFFFF05;
    border: 1px dashed transparent;
    cursor: pointer;
    transition: border 0.2s ease-in-out;
}
.landing-scroll-card-container:hover{
    border: 1px dashed #FFFFFF;
    background: #000000;
    .scroll-card-profile-button{
        background: #FFFFFF3D;
    }
}
.agency-logo-container{
    width: 52px;
    height: 52px;
    border-radius: 12px;
    position: absolute;
    top: -20px;
    left: 20px;
}
.agency-logo-container img{
    width: 100%;
    height: 100%;
    border-radius: 12px;
}
.agency-scroll-card-content{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.agency-scroll-card-ratings{
    display: flex;
    justify-content: flex-end;
    padding: 16px 20px 0px 0px;
    gap: 4px;
}
.scroll-card-rating-image{
    width: 16px;
    height: 16px;
}
.scroll-card-rating-image img{
    width: 100%;
    height: 100%;
}
.scroll-card-rating-number{
    color: #FFFFFF;
    font-weight: 700;
    font-size: var(--font-size-3);
}
.scroll-card-company-details-container{
    display: flex;
    gap: 12px;
    flex-direction: column;
}
.company-details-sub-container{
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0px 20px;
}
.scroll-card-company-name{
    color: #FFFFFF;
    font-size: var(--font-size-2);
    font-weight: 600;
}
.scroll-card-keyinfo-container{
    display: flex;
    gap: 8px;
    align-items: center;
}
.scroll-card-info-box{
    display: flex;
    gap: 3px;
    align-items: center;
}
.scroll-card-info-image{
    width: 14px;
    height: 14px;
}
.scroll-card-info-image img{
    width: 100%;
    height: 100%;
}
.scroll-card-info-text{
    color: #FFFFFF66;
    font-size: 10px;
    font-weight: 600;
}
.seperator-image{
    width: 3px;
    height: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.seperator-image img{
    width: 100%;
    height: 100%;
}
.verification-image{
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
}
.verification-image img{
    width: 100%;
    height: 100%;
}
.scroll-card-verification-text{
    color: #FFFFFFB2;
    font-weight: 700;
    font-size: 10px;
}
.scroll-card-service-container{
    padding: 4px 10px;
    border: 1px dashed #FFFFFF3D;
    border-radius: 2px;
}
.scroll-card-services{
    color: #FFFFFF;
    font-weight: 400;
    font-size: var(--font-size-4);
}
.scroll-card-previous-clients{
    color: #FFFFFF80;
    font-weight: 600;
    font-size: 10px;
}
.scroll-card-clients-image-container{
    display: flex;
    gap: 4px;
}
.previous-clients-image{
    width: 28px;
    height: 28px;
    border-radius: 74px;
}
.landing-header-logo{
    width: 40px;
    height: 40px;
}
.landing-header-logo img{
    width: 100%;
    height: 100%;
}
.landing-app-name{
    width:68px;
    height:40px;
}
.landing-app-name img{
    width: 100%;
    height: 100%;
}
.previous-clients-image img{
    width: 100%;
    height: 100%;
    border-radius: 74px;
}
.scroll-card-profile-button{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background: #FFFFFF0A;
    cursor: pointer;
    align-items: center;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}
.scroll-card-arrow{
    width: 16px;
    height: 16px;
}
.scroll-card-arrow img{
    width: 100%;
    height: 100%;
}
.landingpage-topservice-title{
    display: none;
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
    .landing-header-container{
        padding: 26px 40px;
    }
    .landingpage-toptier-container{
        padding: 0px 40px;
        width: calc(100% - 80px);
    }
    .landingpage-footer-container{
        padding: 153px 0px 40px 0px;
    }
    .landingpage-footer-category{
        padding: 0px 40px;
    }
    .footer-end-container{
    padding: 30px 40px;
    width: calc(100% - 80px);
    }
}
@media only screen and (max-width: 991px) {
    .landing-header-logo{
        width: 28px;
        height: 28px;
    }
    .landingpage-topservice-title{
        color: #A9F005;
        font-weight: 600;
        font-size: 14px;
        display: block;
        padding-left: 20px;
    }
    .landing-scroll-card-container{
        min-width: 200px;
    }
    .scroll-card-company-details-container{
        gap: 8px;
    }
    .agency-scroll-card-content{
        gap: 12px;
    }
    .landing-app-name{
        width: 49px;
        height: 29px;
    }
    .landing-header-container{
        padding: 13px 20px;
    }
    .company-details-sub-container{
        padding: 0px 10px;
    }
    .scroll-card-rating-number{
        font-size: var(--font-size-4);
    }
    .scroll-card-company-name{
        font-size: var(--font-size-3);
    }
    .scroll-card-services{
        font-size: 10px;
    }
    .agency-scroll-card-ratings{
        padding: 10px 12px 0px 0px;
    }
    .agency-logo-container{
        width: 45px;
        height: 45px;
    }
    .previous-clients-image{
        width: 20px;
        height: 20px;
    }
    .footer-end-container{
        padding: 20px 20px;
        width: calc(100% - 40px);
        }
        .landingpage-footer-container{
            align-items: flex-start;
        }
    .landingpage-footer-category-tags{
        width: fit-content;
        height: 24px;
        align-items: flex-start;
    }
    .footer-category-name{
        font-size: var(--font-size-4);
    }
    .landingpage-footer-category{
        padding: 0px 20px;
        flex-direction: column;
    }
    .landingpage-footer-container{
        padding: 80px 0px 20px 0px;
        gap:30px;
    }
    .landing-navigation-tabs{
     display: none;
    }
    .landingpage-components-container {
        padding: 35px 0px 0px 0px;
    }
    .landingpage-toptier-container {
        gap: 24px;
    }
    .landingpage-toptier-container{
        padding: 0px 20px;
        width: calc(100% - 40px);
    }
    .toptier-title1 {
        font-size: 20px;
        font-weight: 900;
        line-height: 23px;
        line-height: 1;
    }
    .toptier-title2 {
        font-size: 35px;
    }
    .toptier-title3 {
        font-size: 35px;
    }
    .toptier-content{
        font-size: var(--font-size-4);
        width: fit-content;
    }
    .toptier-info-container{
        flex-direction: column;
        gap: 20px;
    }
    .toptier-button-container {
        flex-direction: column;
        gap: 14px;
    }
    .toptier-info-content1{
        font-size: var(--font-size-3);
    }
    .toptier-info-content2{
        font-size: var(--font-size-4);
    }
    .toptier-info-box{
        /* width: fit-content; */
        border-right: 2px solid #347928;

    }
    .landing-card-carousel{
        gap: 20px;
       padding: 60px 0px 50px 0px;
    }
}