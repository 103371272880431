.agency-form-layout {
 width: 100%;
 display: flex;
}

.agency-form-container {
 padding: 24px;
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 gap: 16px;
 width: 100%;
}
.agency-form-content {
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 gap: 20px;
}
.category-name{
  font-size: 16px;
    font-weight: 600;
    padding: 10px;
}
.agency-form-field {
 display: flex;
 gap: 8px;
 flex-direction: column;
 width: 100%;
}

.listpage-field-name-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}
.form-filter-field-name {
 font-size: var(--font-size-2);
 font-weight: 600;
 line-height: 20px;
 color: var(--content-color);
}

.info-icon {
 width: 16px;
 height: 16px;
 cursor: pointer;
}
.info-icon-img {
 width: 100%;
 height: 100%;
}

.dropdown-option-name {
 font-size: var(--font-size-3);
 font-weight: 400;
 color: var(--secondary-button-color) !important;
}
.checkbox-container {
 display: flex;
 gap: 8px;
 padding: 16px 20px;
 align-items: center;
}
.filter-check-box {
 width: 22px;
 height: 22px;
 border-radius: 4px;
 border: 1px solid #979797;
 justify-content: center;
 display: flex;
 align-items: center;
}
.tick-image {
 width: 12px;
 height: 12px;
 display: flex;
 justify-content: center;
 align-items: center;
}
.tick-image img {
 width: 100%;
 height: 100%;
}
.arrow-icon {
 width: 20px;
 height: 20px;
 cursor: pointer;
}
.arrow-icon img {
 width: 100%;
 height: 100%;
}
.input-field::placeholder {
 color: #7c8698;
}
.input-field {
 font-size: var(--font-size-3);
 border: none;
 font-weight: 400;
 border-radius: 8px;
 width: 100%;
 line-height: 20px;
}
.input-field:focus-visible {
 outline-width: 0;
}
.filters-dropdown-field {
 display: flex;
 flex-direction: column;
 gap: 4px;
 position: relative;
}
.dropdown-list {
  max-height: 325px;
  overflow: auto;
  box-shadow: 0px 1.67px 3.33px 0px #091e4214;
  border-radius: 8px;
  border: 0.83px solid var(--secondary-button-border-color);
}

.dropdown-list::-webkit-scrollbar {
  width: 5px; 
}

.dropdown-list::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.dropdown-list::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

.dropdown-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}


.filters-dropdown-container {
 width: 100%;
 position: absolute;
 margin-top: 12px;
 background-color: white;
 z-index: 1;
}

.filters-dropdown-content {
 display: flex;
 flex-direction: column;
 gap: 12px;
}
.dropdown-field-container {
 gap: 4px;
 display: flex;
 width: 100%;
 border-radius: 8px;
 cursor: pointer;
}
.search-field-container {
 gap: 4px;
 display: flex;
 width: 100%;
 border-radius: 8px;
 border: 1px solid rgba(204, 204, 204, 1);
}
.search-field {
 padding: 8px 16px;
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.option-field {
 padding: 8px 16px;
 display: flex;
 gap: 8px;
 align-items: center;
 width: 100%;
 text-align: start;
 cursor: pointer;
}
.button-container {
 display: flex;
 padding: 22px 0px;
 align-items: center;
 justify-content: space-between;
}
.button-left {
 display: flex;
 gap: 8px;
 align-items: center;
}
.button-right {
 display: flex;
 gap: 12px;
 align-items: center;
}
.filter-image {
 width: 20px;
 height: 20px;
}
.filter-name {
 color: var(--content-color);
 font-weight: 500;
 font-size: var(--font-size-2);
 line-height: 26px;
}
.clear-button {
 color: #7e7e7e;
 font-size: var(--font-size-3);
 font-weight: 600;
 line-height: 18px;
 text-decoration: underline;
 cursor: pointer;
}
.option-label {
 font-weight: 600;
 font-size: var(--font-size-3);
 color: var(--content-color);
}
