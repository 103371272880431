.home-content-layout {
 width: 100%;
}
.home-content-container {
 padding: 40px 20px;
 height: calc(100% - 80px);
 display: flex;
 flex-direction: column;
 gap: 24px;
}
.category-container {
 display: flex;
 gap: 32px;
 flex-direction: column;
}
.top-service-container {
 display: flex;
 gap: 20px;
 flex-direction: column;
}
.home-category-title {
 font-size: 30px;
 line-height: 41px;
 font-weight: 700;
 color: var(--title-border-color);
}
.category-content {
 display: flex;
 gap: 10px;
 justify-content: center;
 flex-direction: column;
}
.home-category-title span {
 color: var(--content-color);
}
.categories-verification {
 display: flex;
 justify-content: space-between;
 align-items: center;
}
.category-info {
 font-size: var(--font-size-2);
 line-height: 20px;
 font-weight: 600;
 color: #043f2e;
 display: flex;
 gap: 4px;
}
.category-info span {
 font-weight: 400;
 text-decoration: none !important;
}
.verified-image {
 width: 21px;
 height: 21px;
}
.top-service-tag-container {
 padding: 20px;
 background: #daf4ff70;
 margin: 0px -20px;
}
.tag-title-container {
 display: flex;
 gap: 8px;
 align-items: center;
}
.tag-image {
 width: 24px;
 height: 24px;
}
.tag-title {
  color: #00294b;
  font-weight: 700;
  font-size: var(--font-size-1);
  line-height: 26px;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
  max-width: 100%; 
  display: block; 
  text-align: left; 
  position: relative; 
}

.home-info {
 color: #333333;
 font-weight: 400;
 font-size: var(--font-size-2);
 line-height: 20px;
}
.tag-content {
  text-transform: capitalize;
 color: #333333;
 font-weight: 400;
 font-size: var(--font-size-2);
 line-height: 20px;
 cursor: pointer;
 text-align: start;
 width: fit-content;
}
.tag-content:hover {
 text-decoration: underline;
}
.service-list {
 display: flex;
 flex-direction: column;
 gap: 15px;
}
.navigation-container {
 position: relative;
 padding: 20px;
 border-radius: 12px;
 gap: 10px;
 display: flex;
 justify-content: space-between;
 align-items: center;
 box-shadow: 0px 12px 16px 0px #00250214;
 cursor: pointer;
 background: radial-gradient(297.98% 564.02% at 170.62% 463.41%, #2a6f2b 0%, #c8f169 100%);
 border-image-source: linear-gradient(179.42deg, #c8f169 69.82%, #043f2e 325.14%);
}

.navigation-content {
 color: var(--primary-button-color);
 font-weight: 700;
 font-size: var(--font-size-2);
 line-height: 20px;
 width: calc(100% - 20px);
}
.navigation-content span {
 text-decoration: underline;
}
.categories {
 display: flex;
}
.one-side-categories-list {
 display: flex;
 flex-wrap: wrap;
 justify-content: space-between;
 row-gap: 20px;
 margin: 0px -20px;
}
.single-category {
 display: flex;
 gap: 16px;
 flex-direction: column;
 width: 50%;
}
.category-type-title {
 display: flex;
 gap: 8px;
 padding: 15px 20px;
 background: #daf4ff70;
}
.agencylist-container {
 display: flex;
 gap: 16px;
 flex-direction: column;
 padding-left: 20px;
}
.search-bar-container {
 padding: 0px 20px;
 height: 84px;
 display: flex;
 justify-content: center;
 border-bottom: 1px solid rgba(223, 225, 230, 1);
 align-items: center;
 position: sticky;
 top: 0px;
 background: white;
 z-index: 1;
}
.searchBar {
 height: 35px;
 width: 100%;
 display: flex;
 align-items: center;
 border-radius: 8px;
 padding-left: 10px;
 box-sizing: border-box;
 border: 1px solid #00294b;
}
.searchBar:focus {
 outline: 0;
}

.searchBarInput {
 height: 100%;
 width: 100%;
 border-top-right-radius: 999px;
 border-bottom-right-radius: 999px;
 border-left: none;
 border: none;
 padding: 0;
}
.searchBarInput:focus {
 outline: 0;
}
.searchImageWrapper {
 width: 20px;
 height: 20px;
 margin-right: 10px;
 display: flex;
 align-items: center;
 cursor: pointer;
}
.arrow-image {
 width: 20px;
 height: 20px;
}
.searchbar-dropdown-container{
  border: 1px solid #CCCCCC;
  box-shadow: 0px 12px 20px 0px #1F1F1F14;
  position: absolute;
  border-radius: 8px;
  height: 218px;
  width: 94%;
  margin-top: 280px;
  background-color: white;
  overflow: scroll;
}
.dropdown-content {
 display: flex;
 flex-direction: column;
 gap: 18px;
 padding: 18px 20px;
}
.dropdown-value {
 color: var(--content-color);
 font-weight: 600;
 font-size: var(--font-size-3);
 line-height: 18px;
 cursor: pointer;
 width: fit-content;
}
.dropdown-value:hover {
 text-decoration: underline;
}
@media only screen and (max-width: 1100px) {
  .category-info{
    display: block;
  }
}

@media only screen and (max-width: 991px) {
  .home-content-layout {
    width: 100%;
    min-height: calc(100vh - 73px);
   }
 .category-info {
  display: block;
 }
 .home-content-container {
  padding: 20px;
  /* width: 100%; */
  height: calc(100% - 48px);
 }
 .search-bar-container {
  display: none;
 }
 .one-side-categories-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;
  margin: 0px -20px;
  width: fit-content;
 }
 .single-category {
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 100%;
 }
}
