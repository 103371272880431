.sidebar-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}
.head-left-container {
  border-bottom: 1px solid var(--secondary-button-border-color);
  justify-content: start;
  align-items: center;
    display: flex;
    height: 84px;
    padding-left: 24px;
}
.logo-container-desktop{
  cursor: pointer;
  gap: 9px;
  justify-content: start;
  align-items: center;
    display: flex;
}
.sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.logo {
  width: 40px;
  height: 40px;
}
.app-name{
  width:68px;
  height:40px;
}
/* 
.sidebar-tab:hover {
  background: #EEF2E380;
} */
.sidebar-tab {
  width: 204px;
  height:60px;
  display: flex;
  align-items: center;
justify-content: flex-start;
gap:12px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  background: none;
  padding-left: 12px;
}
.sidebar-tab:focus-visible {
  outline: none;
  background-color: #8080801c;
}
.tab-title {
  font-size: 18px;
  font-weight: 400;
  text-align: start;
  color: var(--content-color);
}
.coming-soon{
  font-size: 14px;
  font-weight: 700;
}

@media only screen and (max-width: 991px) {
  .button-container {
    display: none;
  }
  .coming-soon{
    font-size: 10px;
    font-weight: 700;
  }
  .sidebar-content {
    flex-direction: row;
    justify-content: space-between;
    gap: 0px;
  }
  .head-left-container {
    display: none;
  }
  .sidebar-container {
    padding: 0px;
    display: flex;
    width: 100%;
  }
  .tab-title {
    font-size: var(--font-size-4);
    line-height: 15px;
    text-align: center;
  }
  .sidebar-tab {
    height: 40px;
    flex-direction: column;
    gap: 4px;
    width: 98px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    cursor:pointer
  }
}

.tab-icon {
  width: 24px;
  height: 24px;
}

.tab-icon img {
  width: 100%;
  height: 100%;
}


