body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  
}
body {
  font-family: 'DM Sans', sans-serif;
  text-underline-offset: 1.5px;
  text-decoration-skip-ink: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace, DM Sans;
}
a {
  text-decoration: none;
}
.container {
  height: 100%;
}
div {
  margin: 0;
}
html {
  height: 100%;
  font-family: DM Sans !important;
}
button{
  font-family: DM Sans;
}
textarea{
  font-family: DM Sans !important;
}
code{
  font-family: DM Sans;
}
p {
  margin: 0;
  font-family: DM Sans !important;
}
a {
  font-family: DM Sans !important;
}
ul {
  margin: 0;
  padding: 0px 15px;
}
input{
  border: none;
  font-family: DM Sans;

}
input:focus-visible{
  outline: none;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(188, 178, 178);
  border-radius: 10px;
}

.Mui-selected {
  color: var(--primary-color) !important;
}


.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-weight: 600;
  font-size: var(--font-size-4);
  line-height: 16px;
  text-transform: none;
}
.MuiTabs-indicator {
  background-color: var(--primary-color) !important;
}

.MuiTabs-scroller {
  display: flex !important;
  justify-content: center !important;
}
.MuiBreadcrumbs-separator {
  margin: 0px !important;
}
.MuiTabs-scroller {
  justify-content: flex-start !important;
}
.PhoneInputCountry{
  gap:7px;
}
.MuiAccordion-root	{
  box-shadow: none;
  border-bottom:1px solid #F0F0F0;
  background-color: transparent;
  padding:0;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root::before{
  opacity:0;
  
}
.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root{
  padding:0;
}
.css-15v22id-MuiAccordionDetails-root	{
  padding:0;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-expanded{
  margin:0;
}
.css-pwcg7p-MuiCollapse-root{
  padding-bottom: 20px;
}
@media only screen and (max-width: 991px) {
.css-1xfhfak-MuiTypography-root-MuiLink-root{
  max-width: 100px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.breadcrumb{
  max-width: 100px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important; 
}
.MuiLink-root{
  max-width: 100px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.css-4nmryk-MuiBackdrop-root-MuiModal-backdrop{
  background-color: transparent !important;
}
.MuiBackdrop-root{
  background-color: transparent !important;
}
.crisp-client .cc-1brb6[data-full-view=true] .cc-1yy0g .cc-1m2mf:not(.other-class) {
  bottom: 125px !important;
}
}

