.card-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 20px;
}
.card-content {
  display: flex;
 justify-content: space-between;
 align-items: center;
}
.card-left-container{
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
}
.box {
  width: 56px;
  height: 56px;
}
.company-name {
  font-size: var(--font-size-2);
  font-weight: 700;
  line-height: 20px;
  color: var(--content-color);
}
.company-rating-container{
  display: flex;
  gap:4px;
  align-items: center;
}
.company-rating{
  align-items: center;
  display: flex;
  gap: 4px;
}
.rating-star{
  width: 20px;
  height: 20px;
}
.rating-content{
  font-size: var(--font-size-2);
  font-weight: 700;
  line-height: 20px;
  color: var(--content-color);

}
.rating-content span{
  color: #979797;
}
.moto{
  font-size: var(--font-size-3);
  font-weight: 600;
  line-height: 18px;
  color: #979797;

}
.seperator{
  border: 1px solid #F0F0F0;
}
.description-company {
  font-size: var(--font-size-2);
  font-weight: 400;
  line-height: 21px; 
  color: var(--content-color);
  display: -webkit-box;
  overflow: hidden;
  position: relative;
} 
 .description-company > span:nth-child(even) {
  padding-left: 5px;
  color: rgba(151, 151, 151, 1);
  cursor: pointer;
 }
 
 .description-company > span:nth-child(even):hover {
  text-decoration: underline;
 }

.tags{
  align-items: center;
  display: flex;
  width: fit-content;
}
.tag-name{
  padding: 6px 12px;
  background: #DAF4FF;
  color: #1E1E1E;
  font-weight: 600;
  font-size: var(--font-size-3);
  line-height: 18px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.tag-percent{
  background: #00294B;
  padding: 6px;
color: white;
font-weight: 600;
font-size: var(--font-size-3);
line-height: 18px;
border-top-right-radius: 6px;
border-bottom-right-radius: 6px;
}
.key-info-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
}
.key-image{
  width:16px;
  height: 16px;
}
.key-info{
  font-weight: 600;
  font-size: var(--font-size-3);
  line-height: 18px;
  color: #000000;
}
.border-seperator{
  border: 1px solid #1F1F1F26;
  height: 20px;
}
.button-container{
  display: flex;
  gap: 9px;
  align-items: center;
}
.card-end-line{
  border-bottom: 1px solid #E4E4E4;
  flex:1;
  margin-left: -20px;
  margin-right: -20px;
}
.responsive-key-info-container{
  display: none;
}
.responsive-key-info{
 display: none;
}
.responsive-line{
  display: none;
}


@media only screen and (max-width: 991px) {
  .right-container {
    display: none;
  }
  .responsive-line{
    border-bottom: 1px solid #E4E4E4;
    margin-left: -20px;
    margin-right: -20px;
    flex:1;
    display: block;
  }
  .responsive-key-info-container{
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  .responsive-key-info{
    width: 158px;
    display: flex;
    gap: 4px;
    align-items: center;
  }
  .key-info{
    font-weight: 600;
    font-size: var(--font-size-4);
    line-height: 15px;
    color: #000000;
  }

  .card-container {
    padding: 0px;
  }
  .key-info-container{
    display: none;
  }
  .card-end-line{
   display: none;

  }
  .rating-content{
    font-size: var(--font-size-3);
  line-height: 18px;
  }
}

@media only screen and (max-width: 1280px) {
  .profile-button-name {
    padding: 6px 6px;
    font-size: var(--font-size-4);
  }
  .location-name {
    font-size: var(--font-size-4);
  }
  .card-content {
    gap: 8px;
  }
  .box {
    width: 45px;
    height: 45px;
  }
  .title {
    font-size: var(--font-size-2);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1080px) {
  .card-container {
    padding: 24px 10px;
  }
}
