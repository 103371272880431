.pagination-container {
 display: flex;
 gap: 8px;
 align-items: center;
}
.page-shift-button{
    display: flex;
    gap: 4px;
    padding: 7px 8px;
    background:none;
    border: none;
    font-size: var(--font-size-3);
    font-weight: 600;
    align-items: center;
    color: var(--content-color);
cursor: pointer;
}
.page-shift-button:hover {
 text-decoration: underline;
}
.arrow-image {
 width: 14px;
 height: 14px;
}
.arrow-image img {
 width: 100%;
 height: 100%;
}
.number-box {
 border: 1px solid #cccccc;
 height: 32px;
 width: 40px;
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 8px;
}
.page-content span {
 text-decoration: underline;
}
.page-content {
 display: flex;
 align-items: center;
 gap: 6px;
}
.responsive-pagination-container {
 display: none;
}
@media only screen and (max-width: 991px) {
 .responsive-pagination-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #F7F7F7;
 }
 .responsive-pagination-container .page-content {
  width: 50%;
  padding-left: 20px;
 }
 .responsive-pagination-container .button-container {
  width: 50%;
  justify-content: flex-end;
  padding: 8px 0px;
  padding-right: 20px;
 }
 .number-box {
  background: #ffffff;
 }
 .pagination-container {
  display: none;
 }
 .page-shift-button {
  border-radius: 8px;
  background: #ffffff;
 }
 .button-container {
  display: flex;
  gap: 8px;
 }
}
