/* #######-----LandingTop1PercentAgencies_3-----####### */
.duration-wrapper {
 display: flex;
 flex-direction: column;
 align-items: center;
 gap: 60px;
 padding: 80px 0px;
 max-width: 1176px;
}

.duration-box {
 display: flex;
 justify-content: center;
}

.duration-heading {
 display: flex;
 flex-direction: column;
 align-items: center;
 background: linear-gradient(90deg, #ffffff 0%, #999999 100%) !important;
 background-clip: text !important;
 -webkit-text-fill-color: transparent !important;
}

.duration-title-1,
.duration-title-2 {
 font-size: 40px;
 font-weight: 400;
 line-height: 50px;
}

.duration-title-2 > span {
 font-size: 40px;
 font-weight: 700;
 line-height: 50px;
}



.duration-card-wrapper {
 display: flex;
 align-items: center;
}

.duration-details {
 display: flex;
 flex-direction: column;
 gap: 32px;
}

.duration-text-content {
 display: flex;
 flex-direction: column;
 gap: 4px;
}

.duration-details-title {
 font-size: 24px;
 font-weight: 800;
}

.duration-details-title > span {
 color: var(--primary-color);
 font-size: 24px;
 font-weight: 800;
}

.duration-details-image {
 width: 76px;
 height: 76px;
}

.duration-rightArrow-content {
 display: flex;
 padding: 0px 40px;
}

.duration-rightArrow-icon {
 width: 10px;
 height: 16px;
}

.duration-details-description {
 font-size: 14px;
 font-weight: 300;
 color: #c5c5c5;
}

.duration-nav-Button {
 width: 220px;
}

.expertise-skills-button {
 display: flex;
 border: 1px dashed white;
 border-radius: 8px;
 gap: 10px;
 align-items: center;
 padding: 0 12px;
 cursor: pointer;
}

.duration-button-img {
 width: 20px;
 height: 20px;
}

/* #######-----LandingTop1PercentAgencies_4-----####### */
.top1-wrapper {
 padding: 130px 0 0 0;
 display: flex;
 flex-direction: column;
 gap: 150px;
 max-width: 1176px;
}

.top1-box {
 display: flex;
 justify-content: center;
 position: relative; /* This is necessary for positioning the pseudo-element */
 background-color: transparent; /* or choose any color */
 z-index: 10;
}

.top1-main-content {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 gap: 80px;
}

.top1-heading {
 display: flex;
 flex-direction: column;
 align-items: center;
}

.top1-main-title {
 font-size: 40px;
 font-weight: 400;
 background: linear-gradient(90deg, #ffffff 0%, #999999 100%);
 background-clip: text;
 -webkit-text-fill-color: transparent;
}

.top1-title-description {
 font-size: 14px;
 font-weight: 400;
 line-height: 28px;
 background: linear-gradient(90deg, #ffffff 0%, #999999 100%);
 background-clip: text;
 -webkit-text-fill-color: transparent;
 text-align: center;
}

.top1-title-description > span {
 font-size: 14px;
 font-weight: 700;
 line-height: 28px;
}

.top1-card-wrapper {
 display: flex;
 flex-wrap: wrap;
 gap: 60px;
 justify-content: center;
}

.top1-details {
 display: flex;
 gap: 20px;
}

.top1-details-image {
 height: 24px;
 width: auto;
}

.top1-divisions {
 width: 440px;
}

.top1-title {
 font-size: 14px;
 font-weight: 700;
 color: rgba(128, 196, 233, 1);
}

.top1-percentage {
 font-size: 14px;
 font-weight: 700;
 color: rgba(197, 197, 197, 1);
}

.top1-descriotion {
 font-size: 16px;
 font-weight: 400;
 color: rgba(197, 197, 197, 1);
}

/* #######-----LandingConnectWithBestAgencies_5-----####### */
.connect-agencies-portion {
 display: flex;
 justify-content: center;
 padding: 55px 0;
}

.connect-agencies-wrapper {
 display: flex;
 flex-direction: column;
 align-items: center;
 position: relative; /* To position the pseudo-element */
 padding: 55px 150px;
 border-radius: 16px;
 z-index: 1; /* To keep content above the pseudo-element */
 background-color: #000; /* Use a solid background color if necessary */
 overflow: hidden;
}

.connect-agencies-wrapper::before {
 content: ''; 
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 border-radius: 16px;
 z-index: -1;
 animation: rotateGradient 13s infinite;
 filter: blur(40px);
}


@keyframes rotateGradient {
 0% {
  background: linear-gradient(
   319deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 1% {
  background: linear-gradient(
   326.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 2% {
  background: linear-gradient(
   333.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 3% {
  background: linear-gradient(
   340.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 4% {
  background: linear-gradient(
   347.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 5% {
  background: linear-gradient(
   2.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 6% {
  background: linear-gradient(
   9.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 7% {
  background: linear-gradient(
   16.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 8% {
  background: linear-gradient(
   23.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 9% {
  background: linear-gradient(
   31deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 10% {
  background: linear-gradient(
   38.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 11% {
  background: linear-gradient(
   45.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 12% {
  background: linear-gradient(
   52.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 13% {
  background: linear-gradient(
   59.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 14% {
  background: linear-gradient(
   67deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 15% {
  background: linear-gradient(
   74.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 16% {
  background: linear-gradient(
   81.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 17% {
  background: linear-gradient(
   88.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 18% {
  background: linear-gradient(
   95.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 19% {
  background: linear-gradient(
   103deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 20% {
  background: linear-gradient(
   110.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 22% {
  background: linear-gradient(
   117.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 24% {
  background: linear-gradient(
   124.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 25% {
  background: linear-gradient(
   131.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 26% {
  background: linear-gradient(
   139deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 27% {
  background: linear-gradient(
   146.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 28% {
  background: linear-gradient(
   153.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 29% {
  background: linear-gradient(
   160.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 30% {
  background: linear-gradient(
   167.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 31% {
  background: linear-gradient(
   175deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 32% {
  background: linear-gradient(
   182.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 33% {
  background: linear-gradient(
   189.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 34% {
  background: linear-gradient(
   196.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 35% {
  background: linear-gradient(
   203.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 36% {
  background: linear-gradient(
   211deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 37% {
  background: linear-gradient(
   218.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 38% {
  background: linear-gradient(
   225.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 39% {
  background: linear-gradient(
   232.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 40% {
  background: linear-gradient(
   239.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 41% {
  background: linear-gradient(
   247deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 42% {
  background: linear-gradient(
   254.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 43% {
  background: linear-gradient(
   261.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 44% {
  background: linear-gradient(
   268.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 45% {
  background: linear-gradient(
   275.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 46% {
  background: linear-gradient(
   283deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 47% {
  background: linear-gradient(
   290.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 48% {
  background: linear-gradient(
   297.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 49% {
  background: linear-gradient(
   304.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 50% {
  background: linear-gradient(
   311.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 51% {
  background: linear-gradient(
   319deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 52% {
  background: linear-gradient(
   311.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 53% {
  background: linear-gradient(
   304.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 54% {
  background: linear-gradient(
   297.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 55% {
  background: linear-gradient(
   290.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 56% {
  background: linear-gradient(
   283deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 57% {
  background: linear-gradient(
   275.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 58% {
  background: linear-gradient(
   268.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 59% {
  background: linear-gradient(
   261.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 60% {
  background: linear-gradient(
   254.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 61% {
  background: linear-gradient(
   247deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 62% {
  background: linear-gradient(
   239.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 63% {
  background: linear-gradient(
   232.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 64% {
  background: linear-gradient(
   225.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 65% {
  background: linear-gradient(
   218.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 66% {
  background: linear-gradient(
   211deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 67% {
  background: linear-gradient(
   203deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 68% {
  background: linear-gradient(
   196.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 69% {
  background: linear-gradient(
   189.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 70% {
  background: linear-gradient(
   182.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 71% {
  background: linear-gradient(
   175deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 72% {
  background: linear-gradient(
   167.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 73% {
  background: linear-gradient(
   160.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 74% {
  background: linear-gradient(
   153.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 75% {
  background: linear-gradient(
   146.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 76% {
  background: linear-gradient(
   139deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 77% {
  background: linear-gradient(
   131.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 78% {
  background: linear-gradient(
   124.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 79% {
  background: linear-gradient(
   117.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 80% {
  background: linear-gradient(
   110.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 81% {
  background: linear-gradient(
   103deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 82% {
  background: linear-gradient(
   95.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 83% {
  background: linear-gradient(
   88.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 84% {
  background: linear-gradient(
   81.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 85% {
  background: linear-gradient(
   74.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 86% {
  background: linear-gradient(
   67deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 87% {
  background: linear-gradient(
   59.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 88% {
  background: linear-gradient(
   52.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 89% {
  background: linear-gradient(
   45.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 90% {
  background: linear-gradient(
   38.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 91% {
  background: linear-gradient(
   31deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 92% {
  background: linear-gradient(
   23.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 93% {
  background: linear-gradient(
   16.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 94% {
  background: linear-gradient(
   9.5deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 95% {
  background: linear-gradient(
   2.2deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 96% {
  background: linear-gradient(
   355deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 97% {
  background: linear-gradient(
   347.8deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 98% {
  background: linear-gradient(
   340.6deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 99% {
  background: linear-gradient(
   333.4deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
 100% {
  background: linear-gradient(
   319deg,
   #7db8e51a 10%,
   rgb(207 205 205 / 70%) 30%,
   rgb(105 105 207 / 11%) 59%,
   rgb(0 0 0) 90%
  );
 }
}

.connect-agencies-title {
 font-size: 40px;
 color: rgba(197, 197, 197, 1);
 font-weight: 400;
 line-height: 50px;
 background: linear-gradient(90deg, #999999 0%, #ffffff 100%);
 background-clip: text;
 -webkit-text-fill-color: transparent;
}

.connect-agencies-title > span {
 font-size: 40px;
 color: rgba(197, 197, 197, 1);
 font-weight: 700;
 line-height: 50px;
}

.connect-agencies-description {
 color: rgb(208 208 199);
 font-size: 14px;
 font-weight: 400;
 line-height: 28px;
 text-align: center;
}

/* #######-----LandingHireByExpertise and 6,LandindHireBySkills and 7-----####### */
.expertise-wrapper {
 display: flex;
 flex-direction: column;
 gap: 36px;
 align-items: center;
 padding: 80px 0px;
 max-width: 1176px;
}

.expertise-box {
 display: flex;
 justify-content: center;
}

.expertise-heading {
 display: flex;
 flex-direction: column;
 align-items: center;
}

.expertise-title-1 {
 background: linear-gradient(0deg, #000000 18.67%, #347928 47.29%, #a9f005 75.9%, #f3ffe6 133.13%);
 background-clip: text;
 -webkit-text-fill-color: transparent;
 font-size: 40px;
 font-weight: 700;
 line-height: 50px;
}

.expertise-title-2 {
 display: flex;
 text-align: center;
 font-size: 40px;
 font-weight: 700;
 line-height: 50px;
 background: linear-gradient(90deg, #ffffff 0%, #999999 100%);
 background-clip: text;
 -webkit-text-fill-color: transparent;
}

.expertise-cards-container {
 width: 100%;
}

.expertise-cards-wrapper {
 display: flex;
 flex-wrap: wrap;
 gap: 22px;
 justify-content: center;
}

.expertise-card {
 width: 200px;
 height: 150px;
 display: flex;
 flex-direction: column;
 align-items: center;
 text-align: center;
 gap: 20px;
 background: #1a1a1a;
 border-radius: 20px;
 justify-content: center;
 transition: background-color 0.3s ease-in-out;
}

.expertise-card:hover {
 background: #000000;
 box-shadow: 0px 0px 40px 4px #ffffff14;

 .expertise-skills-button {
  background: #ffffff33;
 }
}

.expertise-icon {
 width: 32px;
 height: 32px;
}

.skills-icon {
 width: 48px;
 height: auto;
}

.expertise-name {
 font-size: 14px;
 font-weight: 400;
 line-height: 18px;
 color: rgba(255, 255, 255, 1);
 width: 140px;
}

.expertise-skills-query {
 width: 140px;
}

.expertise-skills-button {
 font-size: 14px;
}

/* #######-----LandingHireNextProjectSimple and 8-----####### */
.simple-box {
 background-repeat: no-repeat;
 background-size: cover;
 display: flex;
 justify-content: center;
}
.simple-container {
 display: flex;
 flex-direction: column;
 align-items: center;
 gap: 20px;
 height: 300px;
 justify-content: center;
 width: 100%;
 max-width: 1176px;
 background-repeat: no-repeat;
 background-size:
  65px 65px,
  65px 65px;
 background-position:
  left 100px bottom 80px,
  right 100px top 80px;
}

.simple-contents {
 display: flex;
 flex-direction: column;
 align-items: center;
 gap: 4px;
}

.simple-heading {
 background: linear-gradient(270deg, #a9f005 0%, #ffffff 100%);
 background-clip: text;
 -webkit-text-fill-color: transparent;
 font-size: 40px;
 font-weight: 800;
 line-height: 50px;
}

.simple-description {
 font-size: 14px;
 font-weight: 400;
 line-height: 18px;
 color: rgba(255, 255, 255, 1);
}

.simple-button {
 display: flex;
 align-items: center;
 background: #ffffff;
 gap: 10px;
 width: 220px;
 height: 56px;
 border-radius: 8px;
 cursor: pointer;
}

.simple-button > button {
 height: 100%;
}

@media only screen and (max-width: 380px) {
 .expertise-cards-wrapper {
  gap: 20px !important;
 }

 .expertise-card {
  width: 130px !important;
  height: 120px !important;
  gap: 5px !important;
 }

 .expertise-name {
  font-size: 12px !important;
  line-height: 16px !important;
  width: 80px !important;
 }

 .expertise-icon {
  width: 20px !important;
  width: 20px !important;
 }

 .expertise-skills-query {
  font-size: 12px !important;
  width: 80px !important;
 }

 .expertise-skills-button {
  height: 35px !important;
  width: 100px !important;
 }

 .expertise-skills-button > button {
  font-size: 10px !important;
  height: 100% !important;
 }

 .skills-icon {
  width: 30px !important;
 }

 .simple-container {
  background-position:
   left 0px top 85px,
   right 0px top 35px !important;
  background-size:
   30px 30px,
   30px 30px !important;
 }

 .simple-button {
  height: 36px !important;
 }

 .simple-button > button {
  font-size: 14px !important;
 }

 .simple-button > button > div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
 }

 .top1-box::before {
  background-position:
   left 0px top 20%,
   left 0px top 10%,
   left 0px top 0% !important;
  opacity: 0.2 !important;
 }
}

@media only screen and (max-width: 991px) {
 /* #######-----LandingTop1PercentAgencies_3-----####### */
 .duration-title-1,
 .duration-title-2,
 .duration-title-2 > span {
  font-size: 24px;
  line-height: 30px;
 }

 .duration-heading {
  align-items: flex-start;
 }

 .duration-wrapper {
  padding: 80px 0 40px 0;
  gap: 30px;
  max-width: calc(100% - 40px);
  align-items: flex-start;
 }

 .duration-card-wrapper {
  flex-direction: column;
  gap: 12px;
 }

 .duration-details {
  gap: 24px;
  width: 100%;
 }

 .duration-details-image {
  width: 60px;
  height: 60px;
 }

 .duration-details-title,
 .duration-details-title > span {
  font-size: 20px;
  line-height: 21px;
 }

 .duration-details-description {
  font-size: 12px;
  line-height: 14px;
 }

 .duration-rightArrow-icon {
  width: 6px;
  height: 12px;
 }

 .duration-rightArrow-content {
  transform: rotate(90deg);
 }


 /* #######-----LandingTop1PercentAgencies_4 and 5-----####### */

 .top1-wrapper {
  max-width: calc(100% - 40px);
  gap: 80px;
  padding: 40px 0 0px 0;
 }

 .top1-main-content {
  width: 100%;
  gap: 40px;
 }

 .top1-main-title {
  font-size: 24px;
 }

 .top1-box::before {
  background-position:
   left 0px top 40%,
   left 0px top 20%,
   left 0px top 0%;
  opacity: 0.1;
 }

 .top1-details {
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
 }

 .top1-card-wrapper {
  gap: 28px;
  flex-direction: column;
 }

 .top1-divisions {
  width: 100%;
 }

 .top1-details-image {
  height: 18px;
  width: auto;
 }

 .top1-heading {
  align-items: flex-start;
 }

 .top1-title {
  font-size: 12px;
  line-height: 15px;
 }

 .top1-percentage {
  font-size: 14px;
  line-height: 18px;
 }
 .top1-descriotion {
  font-size: 14px;
  line-height: 18px;
 }

 .top1-title-description,
 .top1-title-description > span {
  font-size: 12px;
  line-height: 14px;
  text-align: left;
 }

 /* #######-----LandingConnectWithBestAgencies_5-----####### */
 .connect-agencies-description {
  font-size: 12px;
  line-height: 15px;
 }

 .connect-agencies-title,
 .connect-agencies-title > span {
  font-size: 28px;
  line-height: 36px;
  text-align: center;
 }

 .connect-agencies-portion {
  padding: 0px;
 }

 .connect-agencies-wrapper {
  padding: 40px;
  display: flex;
  gap: 4px;
  background: linear-gradient(
   320deg,
   rgb(230 234 241 / 27%) 0%,
   rgba(39, 39, 61, 0.644782913165266) 19%,
   rgba(16, 16, 16, 1) 100%
  );
  justify-content: center;
 }

 .connect-agencies-wrapper::before {
  display: none;
 }

 /* #######-----LandingHireByExpertise and 6,LandindHireBySkills and 7-----####### */
 .expertise-title-1,
 .expertise-title-2 {
  font-size: 24px;
  line-height: 30px;
 }

 .expertise-cards-wrapper {
  gap: 22px;
 }

 .experts-and-skills > div:nth-child(1) > div {
  max-width: calc(100% - 40px);
  padding: 80px 0px 40px;
  gap: 28px;
 }

 .experts-and-skills > div:nth-child(2) > div {
  max-width: calc(100% - 40px);
  padding: 40px 0px 40px;
  gap: 28px;
 }

 .expertise-card {
  width: 160px;
  height: 150px;
  gap: 5px;
 }

 .expertise-icon {
  width: 32px;
  height: 32px;
 }

 .expertise-name {
  font-size: 14px;
  line-height: 18px;
 }

 .expertise-skills-query {
  font-size: 14px;
 }

 .expertise-skills-button {
  padding: 0 3px;
  height: 35px;
 }

 .expertise-skills-button > button {
  font-size: 14px;
  height: 100%;
 }

 .expertise-skills-button > button > div {
  width: 100%;
  height: 100% !important;
 }

 .skills-icon {
  width: 48px;
 }

 /* #######-----LandingHireNextProjectSimple and 8-----####### */
 .simple-heading {
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  padding: 40px 0 0;
 }

 .simple-description {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
 }

 .simple-container {
  max-width: calc(100% - 40px);
  height: 270px;
  background-size:
   40px 40px,
   40px 40px;

  background-position:
   left 0px top 85px,
   right 0px top 35px !important;
 }

 .simple-button {
  height: 43px;
 }

 .simple-button > button {
  font-size: 12px;
  height: 100%;
 }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
 .simple-container {
  background-position:
   left 100px bottom 80px,
   right 100px top 80px !important;
  background-size:
   40px 40px,
   40px 40px !important;
 }
 .top1-box::before {
  background-position:
   left 0px bottom 27%,
   left 0px bottom 63%,
   left 0px bottom 100%;
 }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
 /* #######-----LandingTop1PercentAgencies_3-----####### */
 .duration-rightArrow-content {
  display: flex;
  padding: 0px 20px;
 }
 .duration-details-image {
  width: 50px;
  height: auto;
 }
 .duration-wrapper {
  max-width: calc(100% - 80px);
 }
 .duration-details {
  gap: 16px;
  width: 250px;
 }
 .duration-title-1,
 .duration-title-2,
 .duration-title-2 > span {
  font-size: 30px;
  line-height: 35px;
 }
 .duration-details-description {
  font-size: 11px;
 }
 .duration-details-title,
 .duration-details-title > span {
  font-size: 20px;
  line-height: 21px;
 }
 .duration-rightArrow-icon {
  width: 6px;
  height: 12px;
 }

 /* #######-----LandingTop1PercentAgencies_4-----####### */
 .top1-main-title {
  font-size: 30px;
  line-height: 35px;
 }
 .top1-title-description,
 .top1-title-description > span,
 .top1-title,
 .top1-percentage {
  font-size: 12px;
 }

 .top1-wrapper {
  max-width: calc(100% - 80px);
 }

 .top1-descriotion {
  font-size: 14px;
 }

 .top1-details-image {
  height: 18px;
  width: auto;
 }

 .top1-divisions {
  width: 250px;
 }

 .top1-card-wrapper {
  gap: 50px;
 }

 /* #######-----LandingConnectWithBestAgencies_5-----####### */
 .connect-agencies-title,
 .connect-agencies-title > span {
  font-size: 30px;
  line-height: 35px;
 }

 .connect-agencies-description {
  font-size: 12px;
 }

 .connect-agencies-wrapper {
  padding: 45px 60px;
  max-width: calc(100% - 80px);
 }

 /* #######-----LandingHireByExpertise and 6,LandindHireBySkills and 7-----####### */
 .expertise-title-1,
 .expertise-title-2 {
  font-size: 30px;
  line-height: 35px;
 }

 .expertise-wrapper {
  max-width: calc(100% - 80px);
 }

 .expertise-icon {
  height: 26px;
  width: 26px;
 }

 .expertise-name {
  font-size: 12px;
  line-height: 14px;
 }

 .expertise-card {
  width: 150px;
  height: 120px;
  gap: 10px;
 }

 .expertise-skills-query {
  font-size: 12px;
  line-height: 14px;
  width: 120px;
 }

 .expertise-skills-button,
 .expertise-skills-button > button {
  font-size: 12px !important;
 }

 .skills-icon {
  width: 30px;
 }

 /* #######-----LandingHireNextProjectSimple and 8-----####### */
 .simple-heading {
  font-size: 30px;
  line-height: 35px;
 }

 .simple-description {
  font-size: 12px;
  line-height: 14px;
 }

 .simple-button > button {
  font-size: 12px;
  line-height: 14px;
  height: 100%;
 }

 .simple-container {
  background-size:
   50px 50px,
   50px 50px;
  max-width: calc(100% - 80px);
 }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
 /* #######-----LandingTop1PercentAgencies_3-----####### */
 .duration-rightArrow-content {
  display: flex;
  padding: 0px 20px;
 }
 .duration-details-image {
  width: 60px;
  height: auto;
 }
 .duration-details {
  gap: 16px;
  width: 300px;
 }
 .duration-title-1,
 .duration-title-2,
 .duration-title-2 > span {
  font-size: 35px;
  line-height: 40px;
 }
 .duration-details-description {
  font-size: 12px;
 }
 .duration-details-title,
 .duration-details-title > span {
  font-size: 20px;
 }
 .duration-rightArrow-icon {
  width: 6px;
  height: 12px;
 }

 /* #######-----LandingTop1PercentAgencies_4 and 5-----####### */
 .top1-main-title {
  font-size: 35px;
  line-height: 40px;
 }
 .top1-title-description,
 .top1-title-description > span,
 .top1-title,
 .top1-percentage {
  font-size: 13px;
 }

 .top1-descriotion {
  font-size: 15px;
 }

 .top1-details-image {
  height: 20px;
  width: auto;
 }

 .top1-divisions {
  width: 300px;
 }

 .top1-card-wrapper {
  gap: 50px;
 }

 /* #######-----LandingConnectWithBestAgencies_5-----####### */
 .connect-agencies-title,
 .connect-agencies-title > span {
  font-size: 35px;
  line-height: 40px;
 }

 .connect-agencies-description {
  font-size: 13px;
 }

 /* #######-----LandingHireByExpertise and 6,LandindHireBySkills and 7-----####### */
 .expertise-title-1,
 .expertise-title-2 {
  font-size: 35px;
  line-height: 40px;
 }

 .expertise-icon {
  height: 26px;
  width: 26px;
 }

 .expertise-name {
  font-size: 13px;
 }

 .expertise-card {
  width: 180px;
  height: 150px;
 }

 .skills-icon {
  width: 44px;
 }

 .expertise-skills-query {
  font-size: 13px;
  width: 130px;
 }

 .expertise-skills-button,
 .expertise-skills-button > button {
  font-size: 13px !important;
 }

 /* #######-----LandingHireNextProjectSimple and 8-----####### */
 .simple-heading {
  font-size: 35px;
  line-height: 40px;
 }

 .simple-description {
  font-size: 13px;
 }



 .simple-button > button {
  font-size: 13px;
 }
}
