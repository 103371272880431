.forgot-password-container {
 display: flex;
 flex-direction: column;
 gap: 20px;
 width: 100%;
}
.forgot-password-wrapper {
 margin-top: 25px;
}
.forgot-password-content {
 display: flex;
 flex-direction: column;
 gap: 5px;
}
.email-content {
 display: flex;
 flex-direction: column;
 gap: 10px;
}
.title {
 font-weight: 600;
 font-size: var(--font-size-1);
 line-height: 28px;
 color: var(--secondary-button-color) !important;
 text-align: center;
}
.forgot-password-title {
 justify-content: center;
}
.description {
 color: #7c8698;
 font-weight: 400;
 font-size: var(--font-size-4);
 line-height: 16px;
 text-align: center;
}
.email-field {
 display: flex;
 flex-direction: column;
 gap: 4px;
}
.field-name {
 color: var(--secondary-button-color) !important;
 font-weight: 600;
 font-size: var(--font-size-4);
 line-height: 16px;
 letter-spacing: 0.7px;
}
.field {
 border: 1px solid var(--secondary-button-border-color);
 border-radius: 4px;
 width: 100%;
 height: 40px;
 position: relative;
}
.forgot-password-input-field {
 width: calc(100% - 16px);
 padding: 6px 8px;
 font-size: var(--font-size-3);
 font-weight: 400;
 line-height: 20px;
 border: none;
 border-radius: 4px;
 height: calc(100% - 12px);
}
.forgot-password-input-field:focus-visible {
 outline: none;
}

.input-field-login {
 width: calc(100% - 16px);
 padding: 6px 8px;
 font-size: var(--font-size-3);
 font-weight: 400;
 line-height: 20px;
 border: none;
 border-radius: 4px;
 height: calc(100% - 12px);
}

.password-field {
 width: calc(100% - 50px) !important;
}

.input-field-login:focus-visible {
 outline: none;
}

.login-option {
 display: flex;
 gap: 8px;
 width: 100%;
 justify-content: center;
}
.login-content {
 font-size: var(--font-size-3);
 font-weight: 400;
 color: #7c8698;
}
.login {
 font-size: var(--font-size-3);
 font-weight: 600;
 color: var(--primary-color);
 cursor: pointer;
 border-bottom: 1px solid var(--primary-color);
}

.auth-button-container {
 display: flex;
 gap: 8px;
 justify-content: center;
 align-items: center;
}

.auth-btn-title {
 font-size: var(--font-size-3);
 font-weight: 600;
 line-height: 20px;
 display: flex;
 gap: 10px;
 align-items: center;
 letter-spacing: 0.5px;
}
.field-name-container {
 display: flex;
 justify-content: space-between;
}

.forgot-password-link {
 font-size: var(--font-size-4);
 color: rgba(31, 31, 31, 0.6);
 font-weight: 600;
 line-height: 16px;
 cursor: pointer;
 text-decoration: underline;
 text-underline-offset: 2px;
 text-decoration-skip-ink: none;
}

.input-error {
 color: red;
 font-size: x-small;
 display: block;
}

.eye-icon-container {
 position: absolute;
 top: 0;
 right: 0;
 width: 34px;
 height: 40px;
 display: flex;
 justify-content: center;
 align-items: center;
}
.eye-icon-container img{
 cursor: pointer;
}
.form-layout {
 display: flex;
 justify-content: space-between;
 flex-direction: column;
 height: 100%;
 width: 100%;
}

.mail-seccess {
 text-align: center;
 background: #fff;
 display: flex;
 justify-content: center;
}
.success-inner {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100px;
 height: 100px;
}
.mail-seccess .success-inner h1 {
 font-size: 100px;
 text-shadow: 3px 5px 2px #3333;
 /* color: #006DFE; */
 color: #a9f005;
 font-weight: 700;
}
.email-success-message {
 text-align: center;
 font-size: 20px;
 color: #676161;
 font-weight: 600;
}
.email-success-description {
 text-align: center;
 font-size: 14px;
 color: #776b6b;
 display: flex;
 flex-direction: column;
 gap: 3px;
}
.mail-success-login {
 border: none;
 text-decoration: 1.1px solid underline;
 text-underline-offset: 2.5px;
 text-decoration-skip-ink: none;
}
