@media only screen and (max-width: 991px) {
 .responsive-header-container {
  display: flex;
  padding: 0px !important;
  justify-content: space-between;
  width: 100%;
  background: #f9fffa;
  position: sticky;
  top: 0px;
  z-index: 1;
  border-bottom: 1px solid #1f1f1f14;
 }
 .responsive-header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 13px 20px;
  height: 42px;
 }
 .responsive-search-bar-container {
  width: 20px;
  height: 20px;
 }
 .responsive-logo-content {
  display: flex;
  gap: 6px;
  align-items: center;
  width: fit-content;
 }
 .responsive-logo {
  width: 28px;
  height: 28px;
 }
 .responsive-title {
  width: 49px;
  height: 29px;
 }
 .responsive-header-right {
  display: flex;
  gap: 8px;
  align-items: center;
 }
 .responsive-button-container {
  width: 140px;
 }
 .responsive-search-bar {
  background: var(--secondary-color);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 20px;
  height: 20px;
 }
 .search-bar-container {
  width: 20px;
  height: 20px;
 }
 .search-bar-image {
  width: 24px;
  height: 24px;
 }
 .searchBarInput {
  height: 100%;
  width: 100%;
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
  border-left: none;
  border: none;
  padding: 0;
 }
 .searchBarInput:focus {
  outline: 0;
 }
 .dropdown-container {
  border: 1px solid #cccccc;
  box-shadow: 0px 12px 20px 0px var(--content-color);
  position: absolute;
  border-radius: 8px;
  height: 218px;
  width: 90%;
  top: 0px !important;
  margin-top: 80px;
  background-color: white;
  overflow: scroll;
 }
 .dropdown-content {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 18px 20px;
 }
 .dropdown-value {
  color: var(--content-color);
  font-weight: 600;
  font-size: var(--font-size-3);
  line-height: 18px;
  cursor: pointer;
  width: fit-content;
 }
 .dropdown-value:hover {
  text-decoration: underline;
 }
}
@media only screen and (min-width: 992px) {
 .responsive-header-container {
  display: none;
 }
}
