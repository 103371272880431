.button-content-wrapper {
 display: flex;
 justify-content: center;
 align-items: center;
}

.primary-button {
  background-color: var(--primary-color);
  width: 100%;
  cursor: pointer;
  color: var(--primary-button-color);
  font-size: var(--font-size-2);
  border-radius: 8px;
  border: 1px solid var(--primary-button-border-color);
  font-weight: 600;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  transition: background-color 0.2s ease-in-out, 
              border-color 0.2s ease-in-out, 
              color 0.2s ease-in-out;
  background: #FFFFFF; 
  border: 1px solid transparent;
}

.primary-button.hover-hover1:hover {
  border: 1px solid #FFFFFF; 
  background: #101010;  
  color: #FFFFFF;  
  box-shadow: 0px 0px 40px 4px #FFFFFF33;
}
.primary-button.hover-hover2{
  background:#101010;
  border: 1px dashed white;
  border-radius: 8px;
}
.primary-button.hover-hover2:hover{
  background: #FFFFFF33;
  border: 0.5px dashed white;
}
.primary-button.hover-hover3{
  background-color: var(--primary-color);
}
.primary-button.hover-hover3:hover{
  box-shadow: 0px 0px 40px 4px #FFFFFF33;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background: #101010;
}
.secondary-button {
 background-color: var(--secondary-color);
 width: 100%;
 cursor: pointer;
 color: var(--secondary-button-color);
 font-size: var(--font-size-2);
 border-radius: 8px;
 font-weight: 600;
 border: none;
}
.common-button {
 background-color: #0091ff1f;
 width: 100%;
 cursor: pointer;
 color: var(--secondary-button-color);
 font-size: var(--font-size-2);
 border-radius: 8px;
 border: 1px solid var(--title-border-color);
 font-weight: 600;
}

.disable-button {
 background-color: var(--disable-button-bgcolor);
 color: var(--disable-button-color);
 border: none;
}
