.reset-wrapper {
 display: flex;
 flex-direction: column;
 gap: 50px;
 min-width: 320px;
 align-items: center;
}

.reset-title-content {
 display: flex;
 flex-direction: column;
 gap: 10px;
}

.reset-title {
 font-size: 24px;
 color: #91ce04;
 font-weight: 600;
 letter-spacing: 0.3px;
 text-align: center;
}

.reset-title-description {
 font-size: 14px;
 color: #917a7a;
}

.reset-inputs-and-buttons {
 display: flex;
 flex-direction: column;
 gap: 20px;
 width: 100%;
}

.reset-input-fields {
 display: flex;
 flex-direction: column;
 gap: 10px;
}

.reset-password-section {
 display: flex;
 flex-direction: column;
 gap: 5px;
}

.reset-eye-icon {
 position: absolute;
 top: 0;
 right: 0;
 cursor: pointer;
 width: 34px;
 height: 40px;
 display: flex;
 justify-content: center;
 align-items: center;
}

.reset-password-section > span {
 font-size: 14px;
 color: #1f1d1c;
 font-weight: 400;
}

.reset-password-error {
 font-size: x-small;
 line-height: 12px;
 height: 12px;
 color: red;
}

.reset-input-and-error {
 display: flex;
 flex-direction: column;
 gap: 2px;
}

.reset-password-field {
 height: 40px;
 position: relative;
}

.reset-input {
 border: 1px solid #8080809e !important;
 height: 100%;
 padding: 0px 8px;
 border-radius: 4px;
 width: calc(100% - 18px);
}

.reset-button {
 width: 100%;
}

.reset-success-image {
 width: 100px;
 height: 100px;
}

.reset-success-description {
 text-align: center;
 font-size: 15px;
 color: #786c6c;
}

.reset-success-wrapper {
 display: flex;
 flex-direction: column;
 gap: 30px;
 align-items: center;
 max-width: 320px;
}
