.topagency-container {
 display: flex;
 flex-direction: column;
 gap: 12px;
}
.home-content-layout {
 width: 100%;
}
.topagency-content {
 padding: 40px 20px;
 display: flex;
 gap: 4px;
 flex-direction: column;
 background: #fff2ce80;
}
.title-container {
 display: flex;
 justify-content: space-between;
 align-items: center;
 flex-direction: row;
}
.title {
 color: #00294b;
 font-size: var(--font-size-1);
 font-weight: 700;
 width: 100%;
 display: flex;
 align-items: center;
 text-align: left;
}
.category-content-container {
 display: flex;
 gap: 16px;
 flex-direction: column;
}
.trophy-image {
 width: 24px;
 height: 24px;
}
.trophy-image img {
 width: 100%;
 height: 100%;
}
.content{
  color: #333333;
font-weight: 400;
font-size: var(--font-size-2);
line-height: 20px;
}
.categories-container {
 display: flex;
 gap: 20px;
 flex-direction: column;
}
.category-types-container {
 display: flex;
 gap: 16px;
 padding-bottom: 32px;
 flex-direction: column;
}
.category-title-container {
 display: flex;
 padding: 15px 20px;
 background: #daf4ff70;
 gap: 8px;
 align-items: center;
}
.category-image {
 width: 24px;
 height: 24px;
}
.category-image img {
 width: 100%;
 height: 100%;
}
.topAgency-category-title{
  font-weight: 700;
  font-size: var(--font-size-1);
}
.category-type-title-container {
 padding: 0px 20px;
}
.topAgency-category-type-title{
  color: var(--content-color);
font-weight: 700;
font-size: var(--font-size-2);
}
.category-types-content {
 display: flex;
 flex-direction: column;
 gap: 16px;
 padding: 0px 32px;
}
.category-type{
  color: var(--content-color);
  font-weight: 400;
  font-size: var(--font-size-2);
  cursor: pointer;
  width: fit-content;
  text-align: left;
}
.category-type:hover {
 text-decoration: underline;
}

@media only screen and (max-width: 991px) {

.topagency-container{
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: calc(100vh - 117px);
}
}