.verification-layout {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 gap: 40px;
 height: 100%;
}
.verification-container {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: center;
 height: 100%;
}
.verified-image-content {
 width: 134px;
 height: 134px;
 border-radius: 132px;
}
.verified-image-content img{
    width: 100%;
    height: 100%;
}
.waiting-message {
 font-weight: 600;
 font-size: var(--font-size-2);
 line-height: 14px;
}
.waiting-message div {
 text-decoration: underline;
 text-align: center;
}
.skip-buttons {
 display: flex;
 gap: 9px;
 width: 100%;
 padding-top: 20px;
 align-items: center;
}
.button {
 width: 50%;
}
