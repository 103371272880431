.modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 20px;
  right: 20px;
}
.close-icon {
  width: 13px;
  height: 13px;
}
.close-icon img{
  width: 100%;
  height: 100%;
}
.modal-content {
  max-width: 360px;
  width:100%;
  height:100%;
}
.close-container-modal{
  border: 1px solid #1F1F1F33;
   border-radius: 40px;
   padding: 12px;
   display: flex;
   align-items: center;
   justify-content: center;
  cursor: pointer;

}
