.auth-popup-container {
  padding: 30px;
  max-width: 450px;
  border: 1px solid #e4e4e4;
  border-radius: 20px;
  position: sticky;
  border: 1px solid #B6B6B6;
  height: fit-content;
  box-shadow: 0px 12px 40px 0px #00539714;
}

.content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.login-title-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
.login-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 47px;
  color: var(--content-color);
}
.about-content{
  color: var(--title-border-color);
font-weight: 600;
font-size: var(--font-size-2);
line-height: 20px;
}
.verified-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.verified-image{
  width:20px;
  height: 20px;
}
.verified{
  color: #043F2E;
  text-decoration: underline;
  font-weight: 600;
  font-size: var(--font-size-2);
  line-height: 20px;
  text-underline-offset: 2.5px;
  text-decoration-skip-ink: none;
}
.sub-title {
  text-align: center;
  font-weight: 600;
  font-size: var(--font-size-3);
  line-height: 16px;
  color: var(--content-color);

}

.body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.sso-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.sso-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}
.line-container{
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}
.line-width{
  border-bottom: 3px solid var(--primary-color);
  width:40px;
}
.line2-width{
  border-bottom: 3px solid #1F1F1F66;
  width:40px;
}

/* --------------------"continue with" styles---------------------*/
.continue-with-container {
  display: flex;
  align-items: center;
  gap: 21px;
}


.line {
  height: 1px;
  background-color: var(--secondary-button-border-color);
  width: 56px;
}
@media only screen and (max-width: 991px) {
  .auth-popup-container {
    padding: 20px;
    max-width: 100%;
    border: none;
    border-radius: 0px;
    position: none;
  box-shadow: none;
  width:100%;
  }
}


