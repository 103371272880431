.card-box {
 width: 48px;
 height: 48px;
}
.card-box img {
 border-radius: 12px;
}
.image-container {
 width: 35px;
 height: 32px;
 left: 30px;
 top: 40px;
}
.rating-score {
 position: absolute;
 top: 49%;
 left: 50%;
 transform: translate(-50%, -50%);
 color: var(--primary-color);
 font-size: var(--font-size-4);
 text-align: center;
 pointer-events: none;
}
.agency-detail-content {
 display: flex;
 flex-direction: column;
 gap: 8px;
}
.agency-title {
 display: flex;
 gap: 4px;
 align-items: center;
}
.image-content {
 width: 16px;
 height: 16px;
 cursor: pointer;
}

.button-container {
 display: flex;
 gap: 4px;
 height: fit-content;
 align-items: center;
}
.agency-container {
 display: flex;
 justify-content: space-between;
 width: 100%;
}

.body-header-wrapper {
 padding: 24px 24px 0px 24px;
 font-size: var(--font-size-3);
 border-bottom: 1px solid var(--secondary-button-border-color);
 background: #fafbfc;
 display: flex;
 flex-direction: column;
 gap: 30px;
 position: sticky;
 top: 84px;
 z-index: 1;
}

.contact-button {
 border: 1px solid #f7f7f7;
 border-radius: 8px;
 cursor: pointer;
}
.contact-button-content {
 display: flex;
 gap: 8px;
 align-items: center;
 padding: 6px 12px;
}
.contact-button-name {
 font-size: var(--font-size-3);
 font-weight: 700;
 color: #333333;
 width: 73px;
}
.tab-container {
 width: 100%;
 display: flex;
 justify-content: center;
}
.tab-content {
 width: fit-content;
}

.info {
 display: flex;
 gap: 8px;
 font-size: var(--font-size-4);
 color: #4a5568;
}
.image-size {
 width: 16px;
 height: 16px;
}
.motto {
 font-size: var(--font-size-4);
 color: #4a5568;
}
.no-ratings-container{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: var(--font-size-2);
}

.image-size {
 width: 16px;
 height: 16px;
}
/*---------------------------------------new style--------------------------*/
.agency-detail-container {
 display: flex;
}
.body {
 padding: 20px 0;
}
.breadcrumb-container {
 display: block;
 padding-bottom: 12px;
 padding: 0px 20px 0 20px;
}
.agency-detail-title {
 display: flex;
 gap: 6px;
 padding: 20px;
 width: 100%;
 border-bottom: 1px solid #f0f0f0;
}
.agency-detail-title {
 display: flex;
 align-items: center;
 font-size: var(--font-size-1);
 font-weight: 700;
 line-height: 26.16px;
 text-align: left;
}
.company-name-in-profile {
 font-size: var(--font-size-1);
 font-weight: 700;
 line-height: 26.16px;
 text-align: left;
}

.info-container {
 padding: 20px;
 border-bottom: 1px solid #f0f0f0;
 display: flex;
 flex-direction: column;
 gap: 8px;
}

.service-info-container {
 padding: 20px;
 display: flex;
 flex-direction: column;
 gap: 0px;
}

.info-title {
 color: #005397;
 font-weight: 700;
 font-size: var(--font-size-3);
 line-height: 18.31px;
 padding-bottom: 10px;
}

.key-info-body {
 display: flex;
 flex-wrap: wrap;
 column-gap: 50px;
}

.info-content-container {
 padding-top: 20px;
 display: flex;
 width: 166px;
 font-size: var(--font-size-3);
 gap: 6px;
 font-weight: 600;
 line-height: 18.31px;
}

.icon-wrapper {
 width: 18px;
 height: 18px;
 flex-shrink: 0;
}

.ratings-container {
 display: flex;
 font-size: var(--font-size-3);
}

.ratings-sub-wrapper-1 {
 display: flex;
 gap: 4px;
}

.ratings-sub-wrapper-2 {
 display: flex;
 padding-left: 4px;
 color: #979797;
}

.view-all-btn {
 border: none;
 background-color: transparent;
 color: var(--title-border-color);
 text-decoration: underline;
 font-weight: 600;
}
.btn-wrapper {
 display: flex;
 gap: 9px;
}

.sub-title-moto {
 font-size: var(--font-size-3);
 color: #979797;
 font-weight: 600;
}

.company-description {
 font-size: var(--font-size-3);
 color: var(--content-color);
 font-weight: 400;
 display: -webkit-box;
 overflow: hidden;
 position: relative;
}

.company-description > span:nth-child(even) {
 padding-left: 5px;
 color: rgba(151, 151, 151, 1);
 cursor: pointer;
}

.company-description > span:nth-child(even):hover {
 text-decoration: underline;
}

.accordian-summary-wrapper {
 display: flex;
 flex-direction: column;
 gap: 16px;
}

.accordian-service-wrapper {
 display: flex;
 width: 100%;
}
.service-name {
 width: 50%;
}

.service-percentage-wrapper {
 width: 50%;
 display: flex;
 align-items: center;
 gap: 3px;
}

.service-category-project-detail{
    display: flex;
    gap: 6px;
    align-items: center;
}
.service-category-title {
 color: var(--content-color);
 font-size: var(--font-size-3);
 font-weight: 600;
}

.service-name {
 color: #666666;
 font-weight: 400;
 font-size: var(--font-size-3);
}

.service-percentage-wrapper {
 color: #00294b;
 font-weight: 400;
 font-size: var(--font-size-3);
}

.info-body {
 display: flex;
 flex-direction: column;
 gap: 20px;
}

.social-media-wrapper {
 display: flex;
 gap: 12px;
}
.email-wrapper,
.phone-wrapper {
 display: flex;
 gap: 6px;
 font-size: var(--font-size-3);
 font-weight: 600;
 line-height: 18.31px;
 position: relative;
 align-items: center;
}

.clients-wrapper {
 display: flex;
 gap: 4px;
 flex-wrap: wrap;
}

.average-rating-container {
 background-color: #fff2ceb2;
 padding: 8px 12px 8px 12px;
 gap: 4px;
 border-radius: 8px 8px 8px 8px;
}

.ratings-and-reviews-info-container {
 padding: 20px;
 display: flex;
 flex-direction: column;
 gap: 20px;
}

.ratings-number-wrapper {
 display: flex;
}

.rating {
 font-weight: 700;
}

.average-rating-text {
 font-size: var(--font-size-4);
 font-weight: 600;
}

.load-more-btn {
 background-color: transparent;
 border: none;
 color: #005397;
 font-size: var(--font-size-3);
 font-weight: 600;
 line-height: 20px;
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 4px;
 cursor: pointer;
 width: fit-content;
 text-decoration: underline;
}
.load-more-btn-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unlock-phoneno-btn {
 position: absolute;
 border: none;
 left: 30px;
 backdrop-filter: blur(4px);
 background-color: #1f1f1f1f;
 padding: 8px 20px 8px 20px;
 gap: 10px;
 width: 289px;
 border-radius: 8px 8px 8px 8px;
 left: 49px;
 top: -8px;
 font-weight: 600;
 cursor: pointer;
}

.right-container {
 display: flex;
 padding: 40px 20px;
 gap: 24px;
 flex-direction: column;
}
.right-key-title {
 color: #005397;
 font-weight: 700;
 font-size: var(--font-size-2);
}
.right-key-value-container {
 display: flex;
 flex-direction: column;
 gap: 24px;
}
.key-value-content {
 display: flex;
 gap: 6px;
 align-items: center;
}
.key-image {
 width: 18px;
 height: 18px;
}
.key-image img {
 width: 100%;
 height: 100%;
}
.key-value {
 color: #000000;
 font-weight: 600;
 font-size: var(--font-size-2);
}
/*----------------------------------------------------------------------------*/
@media only screen and (min-width: 992px) and (max-width: 1280px) {
 .body-header-wrapper {
  padding: 24px 10px 0px 10px;
  font-size: var(--font-size-4);
  gap: 20px;
 }

 .info {
  gap: 4px;
  font-size: 10px;
 }
 .contact-button-content {
  gap: 4px;
  padding: 6px 8px;
 }
 .contact-button-name {
  font-size: 10px;
 }
 .card-box {
  width: 65px;
  height: 65px;
 }
 .agency-detail-container {
  gap: 8px;
 }
 .image-size {
  width: 12px;
  height: 12px;
 }
}

@media only screen and (max-width: 991px) {
 .body {
  padding: 20px 0;
  min-height: calc(100vh - 117px);
 }
 .key-info-body {
    column-gap: 0px;
   }
}
