.filter-container{
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    padding: 20px;
    border-bottom: 1px solid #F0F0F0;
}
.filter-head{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.modal-filter {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
  .close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
.filter-icon-container{
    display: flex;
    gap: 4px;
    align-items: center;
}
.filter-icon-image{
    width: 20px;
    height: 20px;
}
.filter-icon-image img{
    width: 100%;
    height: 100%;
}
.filter-content{
    color: var(--content-color);
    font-weight: 600;
    font-size: var(--font-size-2);    
}
.close-icon{
    width: 20px;
    height: 20px;
}
.filter-main-container{
    display: flex;
    width: 100%;
    height: calc(100% - 265px);
}
.filter-main-content{
    display: flex;
    width: 50%;
    flex-direction: column;
    border-right: 1px solid #F0F0F0;
    height: 100%;
    overflow: scroll;
}
.filter-category-box{
    padding: 14px 20px;
    border-bottom: 1px solid #F0F0F0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}
.filter-category-name{
    color: #000000;
    font-weight: 600;
    font-size: var(--font-size-3);
}
.selected-number{
    color: var(--title-border-color);
font-weight: 600;
font-size: var(--font-size-2);
}
.filter-checkbox-content{
    display: flex;
    width: 50%;
    flex-direction: column;
    height: 100%;
    overflow: scroll;
}
.responsive-filter-search-option{
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.responsive-filtersearch-box-container{
    border: 1px solid #CCCCCC;
    width: 155px;
    padding: 8px;
    display: flex;
    gap: 4px;
    align-items: center;
    border-radius: 8px;
}
.responsive-searchImageWrapper{
    width: 16px;
    height: 16px;
}
.checkbox-container{
    display: flex;
    gap: 8px;
    padding: 16px 20px;
    align-items: center;
}
.check-box{
    width: 22px;
    height: 22px;
    border-radius: 4px;
    border: 1px solid #979797;
    justify-content: center;
    align-items: center;
display: flex;
}
.option-label{
    color: var(--content-color);
font-weight: 600;
font-size: var(--font-size-3);
width: 105px;
max-width: 100%;
display: block;
text-align: left;
position: relative;
}
.tick-image{
    width: 12px;
    height: 12px;
}
.tick-image img{
    width: 100%;
    height: 100%;
}
.filter-button-container{
    display: flex;
    gap: 9px;
    padding: 14px 20px;
    border-top: 1px solid #F0F0F0;
    width: calc(100% - 40px);
}