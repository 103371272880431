.review-header {
 display: flex;
 gap: 12px;
 flex-direction: column;
 padding-bottom: 16px;
 border-bottom: 1px solid #f0f0f0;
}

.reviews {
 gap: 16px;
 display: flex;
 flex-direction: column;
}

.review-content {
 display: flex;
 gap: 12px;
 align-items: center;
}
.rating-container {
 display: flex;
 justify-content: space-between;
 align-items: center;
 gap: 4px;
}
.profile-pic {
 width: 40px;
 height: 40px;
}
.reviewver-details-container {
 display: flex;
 gap: 4px;
 flex-direction: column;
}
.reviewver-details {
 display: flex;
 flex-direction: column;
}
.reviewver-name {
 font-size: var(--font-size-3);
 font-weight: 600;
 line-height: 20px;
 color: var(--secondary-button-color) !important;
}
.linkedin-image {
 width: 20px;
 height: 20px;
 border-radius: 4px;
 cursor: pointer;
}
.position {
 color: #7c8698;
 font-size: var(--font-size-4);
 font-weight: 400;
 line-height: 16px;
}

.ratings-and-time {
 display: flex;
 gap: 8px;
}
.ratings {
 display: flex;
 align-items: center;
 gap: 4px;
}
.time {
 display: flex;
 gap: 6px;
}
.image-container {
 width: 16px;
 height: 16px;
}
.value {
 color: var(--content-color);
 font-weight: 600;
 font-size: var(--font-size-4);
}
.comment {
 color: #4a5568;
 font-weight: 400;
 font-size: var(--font-size-3);
 line-height: 20px;
 overflow: hidden;
 text-overflow: ellipsis;
 /* max-height: 100px; */
 /* display: -webkit-box;
 -webkit-line-clamp: 2; */
 -webkit-box-orient: vertical;
}

.comment > span:hover {
  text-decoration: underline;
}

.comment > span {
 padding-left: 5px;
 color: rgba(151, 151, 151, 1);
 cursor: pointer;
}
