.agencylist-body-container {
 min-height: calc(100vh - 117px);
}

.body-header-wrapper-container {
 padding: 20px;
 font-size: var(--font-size-3);
 border-bottom: 1px solid var(--secondary-button-border-color);
 background: var(--title-background-color);
}

.body-header-content-container {
 display: flex;
 gap: 12px;
 flex-direction: column;
}

.information {
 color: #4a5568;
 display: flex;
 gap: 6px;
}
.sub-header-info span {
 color: var(--secondary-button-color) !important;
}
.information span {
 background: #7c8698;
 width: 4px;
 height: 4px;
}
.arrow-container {
 display: flex;
 align-items: center;
}

.description {
 color: #6e6e6e;
 line-height: 20px;
}

.body {
 padding: 0px 20px 16px 20px;
}

.sub-header {
 display: flex;
 justify-content: space-between;
 align-items: center;
 background: var(--primary-button-color);
 z-index: 2;
}
.category-name-title {
 color: var(--secondary-button-color) !important;
 font-size: var(--font-size-1);
 font-weight: 600;
 line-height: 28px;
}

.sub-header-info {
 font-size: var(--font-size-3);
 font-weight: 400;
 line-height: 20px;
 display: flex;
 gap: 4px;
}

.bold {
 font-size: var(--font-size-3);
 font-weight: 400;
 line-height: 20px;
 color: var(--secondary-button-color) !important;
}
.sub-header-info-responsive {
 display: none;
}

.bold-responsive {
 display: none;
}

.select-filter {
 font-size: var(--font-size-3);
 color: var(--secondary-button-color) !important;
 line-height: 20px;
 padding-left: 4px;
 display: flex;
 align-items: center;
}

.dropdown-field {
 display: flex;
 flex-direction: column;
 gap: 4px;
 align-items: center;
 position: relative;
 padding-right: 24px;
}

.dropdown-field-name {
 font-size: var(--font-size-3);
 font-weight: 400;
 color: #7c8698;
 display: flex;
 align-items: center;
 line-height: 20px;
}

.select-field {
 padding: 0px 12px;
 height: 50px;
 border-radius: 999px;
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}

.select-field > div {
 width: 100%;
}

.dropdown-field-container {
 font-size: var(--font-size-3);
 gap: 4px;
 display: flex;
 width: 100%;
 border-radius: 999px;
 cursor: pointer;
}
.dropdown-field-container:hover {
 background: #f4f5f7;
}
.no-result-dropdown-field-container {
  font-size: var(--font-size-3);
  gap: 4px;
  display: flex;
  width: 100%;
  border-radius: 999px;
 }

.dropdown-container {
 min-width: 320px;
 border-radius: 8px;
 box-shadow: 0px 1.67px 3.33px 0px #091e4214;
 z-index: 1;
 position: absolute;
 top: 35px;
 background-color: white;
}

.select-field-container {
 border-radius: 999px;
 border: 1px solid var(--secondary-button-border-color);
 position: relative;
 cursor: pointer;
}

.sort-dropdown-container {
 min-width: 200px;
 border-radius: 8px;
 box-shadow: 0px 1.67px 3.33px 0px #091e4214;
 z-index: 1;
 padding: 5px 0px;
 position: absolute;
 top: 35px;
 background-color: white;
}

.pagination-container {
 background-color: white;
 display: flex;
 justify-content: right;
 padding: 10px 0;
 width: 100%;
}

.pagination {
 align-items: left;
}

.cards-container {
 overflow-y: scroll;
 display: flex;
 flex-direction: column;
 gap: 16px;
}
.company-total-number {
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 20px 20px 0px 20px;
}
.company-number {
 color: #043f2e;
 font-weight: 600;
 font-size: var(--font-size-2);
 line-height: 20px;
 text-decoration: underline;
 width: fit-content;
}
.responsive-filter-container {
 display: none;
}
.responsive-clear-filter-container{
  display: none;
}

.nodata-container {
 display: flex;
 justify-content: center;
flex-direction: column;
gap: 20px;
padding: 20px;
}
.nodata-text{
  color: #979797;
font-weight: 600;
font-size: 18px;
line-height: 18px;
}
.nodata-img-container{
  display: flex;
  justify-content: center;
 flex-direction: column;
 align-items: center;
}
.nodata-img-container img{
  width: 385px;
  height: 280px;
}
.filter-dropdown-container {
 display: none;
}

@media only screen and (max-width: 1075px) {
 .sub-header {
  padding-left: 0px;
  padding-right: 0px;
 }
}

@media only screen and (max-width: 991px) {
 .body-header-wrapper-container {
  padding: 14px 24px;
 }
 .responsive-filter-container {
  display: flex;
  border: 1px solid #cccccc;
  border-radius: 8px;
  cursor: pointer;
 }
 .nodata-container {
 gap: 15px;
 padding: 0px;
 }
 .nodata-text{
  color: #979797;
font-weight: 600;
font-size: 14px;
line-height: 18px;
}
 .nodata-img-container {
  display: flex;
  justify-content: center;
 
 }
 .nodata-img-container img{
  width: 230px;
  height: 150px;
}
 .responsive-clear-button{
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #0091FF;
  text-decoration: underline;
  cursor: pointer;
 }
 .responsive-clear-filter-container{
  display: flex;
  gap: 12px;
  align-items: center;
 }
 .company-total-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 0px 0px;
 }
 .list-container {
  padding: 0px 20px 16px;
 }
 .responsive-filter {
  display: flex;
  padding: 8px;
  gap: 4px;
 }
 .filter-image {
  width: 20px;
  height: 20px;
 }
 .filter-image img {
  width: 100%;
  height: 100%;
 }
 .filter-text {
  color: var(--content-color);
  font-weight: 600;
  font-size: var(--font-size-2);
 }
 .cards-container {
  overflow-y: clip;
 }
 .sub-header-info-responsive {
  display: flex;
  font-size: var(--font-size-3);
  font-weight: 400;
  line-height: 20px;
  display: flex;
  gap: 4px;
 }
 .pagination-container {
  justify-content: space-between;
  background: #f7f7f7;
  bottom: 72px;
  position: sticky;
 }
 .bold-responsive {
  font-size: var(--font-size-3);
  display: flex;
  font-weight: 600;
  line-height: 20px;
 }
 .body {
  padding: 0px 10px 100px 10px;
 }
 .bold {
  display: none;
 }
 .sub-header-info {
  display: none;
 }
 .filter-dropdown-container {
  display: none;
 }
 .card-container::-webkit-scrollbar {
  display: flex;
 }
}

.bookmark {
 padding: 20px 24px;
 font-size: var(--font-size-1);
 font-weight: 500;
 line-height: 28px;
 color: #535457;
}
@media only screen and (min-width: 992px) and (max-width: 1280px) {
 /* .list-container {
    padding: 0px 10px 12px 10px;
  } */
 .dropdown-field {
  padding-right: 15px;
 }
}
@media only screen and (max-width: 1040px) {
 .select-filter {
  font-size: var(--font-size-4);
 }
 .dropdown-field-name {
  font-size: var(--font-size-4);
 }
 .select-field {
  padding: 4px 8px;
  height: 40px;
 }
 .sort-dropdown-container {
  min-width: 170px;
 }
 .dropdown-field-container {
  font-size: var(--font-size-4);
 }
 .dropdown-field {
  padding-right: 10px;
 }
}
@media only screen and (min-width: 992px) and (max-width: 1280px) {
 .body-header-wrapper-container {
  padding: 20px 15px;
 }
}
