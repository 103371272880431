.page-container {
 width: 100%;
 height: 100%;
 display: flex;
}
.landing-page-container {
 width: 100%;
 display: flex;
 height: 100%;
 justify-content: center;
 flex-direction: column;
 align-items: center;
 /* background-image: url('./../../assets/backgroundImage.svg'); */
 background-repeat: no-repeat;
 background-position: 205px 390px;
}
.page-layout-container {
 width: 100%;
 display: flex;
 height: 100%;
 justify-content: center;
 flex-direction: column;
 align-items: center;
}
.project-detail-form-box {
 padding: 25px;
 max-width: 500px;
 border: 1px solid #e4e4e4;
 border-radius: 20px;
 position: sticky;
 border: 1px solid #b6b6b6;
 height: fit-content;
 box-shadow: 0px 12px 40px 0px #00539714;
}
.logo-container {
 display: flex;
 width: 100%;
 height: 100px;
 background: #a9f00514;
 align-items: center;
 justify-content: center;
}
.left-container {
 display: flex;
 gap: 45px;
 flex-direction: column;
 width: 50%;
 position: relative;
}
.project-page-left-container {
 display: flex;
 gap: 45px;
 flex-direction: column;
 width: 50%;
}
.logo {
 width: 40px;
 height: 40px;
}
.title {
 width: 68px;
 height: 40px;
}
.logo-content {
 display: flex;
 gap: 9px;
 align-items: center;
 width: fit-content;
 cursor: pointer;
}
.left-content {
 display: flex;
 gap: 12px;
 flex-direction: column;
}

.element-image {
 width: 324px;
 height: 260px;
 position: relative;
 z-index: 1;
}

.element-image::before {
 content: '';
 position: absolute; /* Now the background behaves as absolute */
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background-repeat: no-repeat;
 background-size: 400px 400px;
 background-position: left -20px bottom -30px;
 background-clip: content-box;
 z-index: 1000;
}

.page-layout {
 max-width: 1176px;
 width: 100%;
 display: flex;
 height: 100%;
 border-right: 1px solid rgba(223, 225, 230, 1);
 min-height: 100vh;
}

.project-page-layout {
 max-width: 1176px;
 width: 100%;
 display: flex;
 justify-content: space-between;
 height: calc(100vh - 100px);
 align-items: center;
}

.home-page-layout {
 max-width: 1176px;
 width: 100%;
 display: flex;
 height: calc(100vh - 100px);
 justify-content: space-between;
 align-items: center;
 overflow: scroll;
}

.sidebar-section {
 min-height: 100vh;
 height: 100%;
 position: sticky;
 top: 0;
}

.main-section {
 height: 100%;
 border-right: 1px solid var(--secondary-button-border-color);
 border-left: 1px solid var(--secondary-button-border-color);
 width: calc(100% - 480.45px);
 min-height: 100vh;
}

.home-main-section {
 height: 100%;
 min-height: 100vh;
 max-width: 632px;
}

.rightside-section {
 width: 300px;
}

.home-logo-container {
 display: block;
 padding: 25px 25px 0px 33px;
}

.category-title {
 font-size: 30px;
 line-height: 41px;
 font-weight: 700;
 color: var(--title-border-color);
}
.category-title span {
 color: var(--content-color);
}
.project-category-info {
 font-size: var(--font-size-2);
 line-height: 20px;
 font-weight: 600;
 color: #043f2e;
 display: flex;
 gap: 4px;
}
.project-category-info span {
 font-weight: 400;
 text-decoration: none !important;
}
.verified-image {
 width: 21px;
 height: 21px;
}

.label-details {
 gap: 5px;
 display: flex;
 flex-direction: column;
 padding: 0px 10px 10px 10px;
}

.label-username {
 font-weight: 600;
 font-size: 18px;
 line-height: 27px;
 color: var(--content-color);
}

.label-email {
 color: rgb(31, 31, 31);
 font-weight: 400;
 font-size: var(--font-size-2);
}

.menu-border-bottom {
 border: 3px solid var(--primary-color);
 border-radius: 5px;
}

/* ------------------------ Temp login design STARTS----------------------- */

.login_content {
 display: flex;
 flex-direction: column;
 height: 100%;
 width: 100%;
 justify-content: flex-start;
 padding-top: 200px;
 align-items: center;
 gap: 100px;
}

.login_Title {
 display: flex;
 gap: 10px;
 align-items: center;
}

.login_Title > div {
 background: #043aa1;
 width: 60px;
 height: 60px;
 border-radius: 12px;
}

.login_msg {
 display: flex;
 flex-direction: column;
 gap: 10px;
}

.login_Title > span {
 font-size: 30px;
 font-weight: 440;
}

.login_button {
 background-color: #043aa1;
 box-shadow: 0px 1px 2px 0px #21212114;
 height: 50px;
 width: 130px;
 border-radius: 8px;
 font-weight: 600;
 font-size: var(--font-size-3);
 color: var(--primary-button-color);
 outline: none;
 border: none;
 cursor: pointer;
}

/* ------------------------ Temp login design ENDS----------------------- */

.responsive-sidebar {
 display: none;
}

.top-services-container {
 padding: 15px 20px;
}
.top-service-title {
 color: #00294b;
 font-weight: 700;
 font-size: var(--font-size-1);
 line-height: 26px;
}
.top-service-values {
 display: flex;
 gap: 16px;
 padding: 0px 20px 40px 20px;
 flex-direction: column;
}
.top-services {
 color: var(--content-color);
 font-size: var(--font-size-2);
 font-weight: 400;
 line-height: 20px;
 width: fit-content;
 cursor: pointer;
}
.top-services:hover {
 text-decoration: underline;
}

.profile-image {
 border-radius: 50px;
 height: 40px;
 overflow: hidden;
 width: 40px;
 align-items: center;
 cursor: pointer;
 display: flex;
}

.head-rightside-container {
 align-items: center;
 background-color: #fff;
 border-bottom: 1px solid var(--secondary-button-border-color);
 box-sizing: border-box;
 display: flex;
 gap: 12px;
 height: 85px;
 padding-left: 20px;
 position: sticky;
 top: 0;
 width: 300px;
 z-index: 3;
}
.get-agency-button {
 width: 145px;
}
.profile-image img {
 width: 100%;
 height: 100%;
}

@media only screen and (max-width: 991px) {
 .landing-page-container {
  background-image: none;
 }
 .logo-container {
  display: none;
 }
 .left-container {
  display: none;
 }
 .project-page-layout {
  flex-direction: column;
  gap: 30px;
 }
 .project-page-left-container {
  display: none;
 }
 .project-detail-form-box {
  margin-top: 0px;
  box-shadow: none;
  border: none;
 }
 .home-page-layout {
  max-width: 1176px;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding-top: 0px;
 }
 .rightside-section {
  display: none;
 }
 .sidebar-section {
  display: none;
 }
 .main-section {
  width: 100%;
  border: none;
 }

 .top-services-container {
  display: none;
 }
 .top-service-values {
  display: none;
 }
 .responsive-sidebar {
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  border-top: 1px solid var(--secondary-button-border-color);
  background: white;
 }
}
.loader {
 top: 50%;
 height: 100%;
 display: flex;
 justify-content: center;
 left: 47%;
 width: 100vw;
 align-items: center;
 position: fixed;
 right: 50%;
 transform: translate(-50%, -50%);
}
.loader-progress {
 width: 40px;
 aspect-ratio: 2;
 ---g: no-repeat
  radial-gradient(circle closest-side, rgba(4, 58, 161, 1) 90%, rgba(4, 58, 161, 0) 100%);
 background:
  var(---g) 0% 50%,
  var(---g) 50% 50%,
  var(---g) 100% 50%;
 background-size: calc(100% / 3) 50%;
 animation: l3 1s infinite linear;
}

@keyframes l3 {
 20% {
  background-position:
   0% 0%,
   50% 50%,
   100% 50%;
 }
 40% {
  background-position:
   0% 100%,
   50% 0%,
   100% 50%;
 }
 60% {
  background-position:
   0% 50%,
   50% 100%,
   100% 0%;
 }
 80% {
  background-position:
   0% 50%,
   50% 50%,
   100% 100%;
 }
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
.home-page-layout{
  padding: 20px;
 width: calc(100% - 40px);
 height: 100%;
}
.project-page-layout{
  padding: 20px;
 width: calc(100% - 40px); 
 height: 100%;
}
}
